import React from "react";
import {
  HeroContainer,
  Herobg,
  Imagebg,
  // Text,
} from "./ServicesHeroSectionElements";

const ServicesHeroSection = () => {
  return (
    <>
      <HeroContainer>
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/ServicesHeroSection.png"}
            alt=""
          />
          {/* <Text>About Us</Text> */}
        </Herobg>
      </HeroContainer>
    </>
  );
};

export default ServicesHeroSection;
