import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ServicesPageTwoHero from "../components/ServicespageTwoHero";

const ServicespageTwo = () => {
  return (
    <>
      <Navbar />
      <ServicesPageTwoHero/>
      <Footer />
    </>
  );
};

export default ServicespageTwo;
