import React from "react";
import BlogComponentFourBody from "../components/BlogComponent4";
import Footer  from "../components/Footer";
import Navbar from "../components/Navbar";

const BlogcomponentFour = () => {
  return <>
      <Navbar/>
      <BlogComponentFourBody/>
      <Footer/>
  </>;
};

export default BlogcomponentFour;
