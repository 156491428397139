import React from "react";
import Benefits from "../components/Benefits";
import Blog from "../components/Blog";
import HeroSection from "../components/HeroSection";

import Navbar from "../components/Navbar";
import Purpose from "../components/OurPurpose";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";

const Homepage = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Purpose />
      <Services />
      <Benefits />
      <Testimonials />
      <Blog />
      <Footer />
    </>
  );
};

export default Homepage;
