import React from "react";
import { Popup, PopupInner, Button } from "./PopUpElements";

function PopUp2(props) {
  return props.trigger ? (
    <div>
      <Popup>
        <PopupInner>
          <Button onClick={() => props.setTrigger(false)}>Okay</Button>
          {props.children}
        </PopupInner>
      </Popup>
    </div>
  ) : (
    ""
  );
}

export default PopUp2;
