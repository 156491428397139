import React from "react";
import BlogComponentThreeBody from "../components/BlogComponent3";
import Footer  from "../components/Footer";
import Navbar from "../components/Navbar";

const BlogcomponentThree = () => {
  return <>
      <Navbar/>
      <BlogComponentThreeBody/>
      <Footer/>
  </>;
};

export default BlogcomponentThree;
