import styled from "styled-components";
import { Link } from "react-router-dom";


export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background: #fff;
  margin-bottom: 220px;

  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 620px 1fr;
  grid-template-rows: 520px;
  background: #fff;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;

  div {
    cursor: pointer;
    
  }
  img {
    position: relative;
    width: 200px;
    height: 150px;
    justify-content: center;
    align-items: center;
    padding-left: 100px;

    @media screen and (max-width: 768px) {
      position: relative;
      width: 100px;
      height: 100px;
      align-items: center;
      padding-left: 0px;
      left: 10px;
      top: 20px;
    }
  }
  h1 {
    align-items: center;
    color: #25937c;
    margin-top: -20px;
    font-size: 34px;
    padding-left: 90px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  h5 {
    color: #25937c;
    align-items: center;
    padding-left: 100px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  h4 {
    color: #25937c;

    float: right;
    margin-right: 200px;
    margin-top: 45px;

    @media screen and (max-width: 768px) {
      margin-right: 191px;
      margin-top: 17px;
    }
  }
  h2 {
    display: flex;
    width: 325px;
    height: 28px;
    float: right;
    font-size: 21px;
    margin-right: -25px;
    margin-top: -150px;

    @media screen and (max-width: 768px) {
      height: 28px;
      width: 67vw;
      float: right;
      font-size: 17px;
      margin-right: 16px;
      margin-top: -69px;
    }
  }
  p {
    display: flex;
    width: 350px;
    height: 45px;
    float: right;
    margin-right: -50px;
    margin-top: -80px;
    color: #939393;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      display: flex;
      width: 55vw;
      height: 45px;
      float: right;
      margin-right: 65px;
      margin-top: -45px;
      color: #939393;
      font-size: 14px;
    }
  }
`;


export const BlogLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  align-items: center;
  /* cursor: pointer; */

  h1 {
    display: none;
    align-items: center;
    color: #25937c;
    margin-top: -20px;
    font-size: 34px;
    padding-left: 90px;
    @media screen and (max-width: 768px) {
      display: block;
      padding-left: 0px;
    }
  }
  h5 {
    display: none;
    color: #25937c;
    align-items: center;
    padding-left: 100px;

    @media screen and (max-width: 768px) {
      display: block;
      padding-left: 0px;
    }
  }

  /* img {
    width: 400px;
    height: 400px;
    padding-top: 150px;

    @media screen and (max-width: 768px) {
      width: 100vw;
      height: 400px;
      padding-right: 0px;
      padding-top: 0px;
      padding-left: 0px;
    }
  } */
`;
export const X = styled.text`
  color: #1d252b;
`;

export const Card = styled.div`
  width: 550px;
  height: 400px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 20px;
  background: #333333;
  margin-top: 150px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 430px;
  }
`;


export const CardImage = styled.img`
  width: 550px;
  height: 320px;
  @media screen and (max-width: 768px) {
    /* width: 70%; */
    width: 100%;
  }
`;

export const CardBody = styled.div`
  margin: 12px;
  /* margin-top: 20px; */
`;


export const CardTitle = styled.h3`
  text-align: left;
  font: normal normal bold 21px/24px Manrope;
  letter-spacing: 0px;
  color: #1d252b;
  position: relative;
  top: -10px;
  color: white;
`;
export const CardDate = styled.p`
  font: normal normal bold 10px Manrope;
  letter-spacing: 0px;
  color: #25937c;
  color: white;
  
`;
