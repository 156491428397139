import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  margin-top: 120px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
   display: flex;
  /* justify-content: center; */
  align-items: flex-start;

  div.square {
    position: relative;
    height: 314px;
    width: 307px;
    background-color: #25937c;

    @media screen and (max-width: 768px) {
      height: 150px;
      width: 150px;
      margin-left: 20px;
    }
  }
  p {
    position: absolute;
    font-size: 45px;
    color: white;
    padding-left: 20px;
    padding-top: 75px;

    @media screen and (max-width: 768px) {
      font-size: 25px;
      padding-top: 20px;
      padding-left: 10px;
    }
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
`;
export const Paragraph = styled.p`
  margin-left: 40px;
  color: #939393;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-left: 0px;
    padding: 10px;
  }
`;
export const Image = styled.img`
  /* width: 60%; */
  height: 550px;
  margin-left: 30px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`;
