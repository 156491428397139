import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SustainibilityPageOne from "../components/SustainibilityPageOne";

const Sustainabilitypage = () => {
  return (
    <>
      <Navbar />
      <SustainibilityPageOne/>
      <Footer />
    </>
  );
};

export default Sustainabilitypage;
