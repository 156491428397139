import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
} from "./BlogComponent1Elements";

const BlogComponentOneBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading> Do Solar Panels Work in frigid Weather?</MainHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog1.jpg"}
            alt=""
          ></Image>
          <Paragraph>
            Even if you are living in rainy climates, solar power remains a
            reliable, resilient, and cost-effective energy source.
          </Paragraph>
          <Paragraph>
            See how solar panel production fares in different weather climates,
            and how you can power forward even in the most frigid conditions.{" "}
          </Paragraph>
          <SubHeading>
            What is the science behind solar panels and temperature?
          </SubHeading>
          <Paragraph>
            Even in below-freezing weather, solar panels turn sunlight into
            electricity. That is because solar panels absorb energy from our
            sun’s abundant light, not the sun’s heat. In fact, cold climates are
            actually optimal for solar panel efficiency. So long as sunlight is
            hitting a solar panel, it will generate electricity. Any diminished
            output during the winter months will primarily be due to shorter
            daylight hours.
          </Paragraph>
          <Paragraph>
            So, how do solar panels work? When sunlight photon particles hit
            solar panel photovoltaic cells, electrons in the silicon are put
            into motion. This creates an electric current, which is sent to your
            home’s electric distribution box to power your essential items. A
            rechargeable solar battery can help store this electricity so you
            can still use solar energy at night, during peak electricity usage,
            or when the grid goes down.{" "}
          </Paragraph>
          <Paragraph>
            Remember how electrons move around atoms? Electrons are at rest (low
            energy) in cooler temperatures. When these electrons are activated
            by increasing sunlight (high energy), a greater difference in
            voltage is attained by a solar panel, which creates more energy.
            That is why solar cells produce electricity more efficiently when
            it’s colder.
          </Paragraph>
          <Paragraph>
            In the winter, it’s also less likely for solar panels to reach their
            peak temperature, or peak power.4 Once their temperature rises above
            that peak temperature, solar panel performance decreases.{" "}
          </Paragraph>
          <Paragraph>
            Research has demonstrated that panels begin losing efficiency around
            77ºF. However, this diminished efficiency is balanced out thanks to
            more daylight hours during the spring and summer months.
          </Paragraph>
          <SubHeading>Do solar panels work when it rains? </SubHeading>
          <Paragraph>
            The sun still delivers daylight to solar panels through the rain and
            clouds. Although solar panels are most productive in direct
            sunlight, they can still use diffuse or indirect sunlight
            (radiation) to generate energy.{" "}
          </Paragraph>
          <Paragraph>
            Rainy days and clouds cause diffuse light. But what is the
            difference? Direct light is solar radiation traveling in a straight
            line from the sun to the earth’s surface. Diffuse light is sunlight
            that has been scattered by particles in the atmosphere yet still
            reaches the earth. Thus, the amount of electricity generated is
            dependent on the density of cloud cover.
          </Paragraph>
          <Paragraph>
            Though energy production decreases with increasingly dense cloud
            cover, panels continue working to a greater capacity than one may
            expect. Rain also helps wash away dust on panels to keep them
            operating efficiently.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentOneBody;
