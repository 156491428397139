import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 78%;
  margin-bottom: 50px;
  /* align-items: flex-start; */

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
`;

export const Highlighted = styled.span`
  color: #25937C;
`;

export const Container1Heading = styled.h2`
  font-size: 35px;
  color: #25937c;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const Paragraph = styled.p`
  font-size: 14px;
  width: 100%;
  margin-top: 0px;
  color: #939393;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  /* align-items: center; */
`;

export const Container2Heading = styled.h2`
  font-size: 36px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Item1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Image = styled.img`
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ImageText = styled.text`
  /* margin-top: 10px; */
  margin-bottom: 5px;
  font-size: 24px;
  color: #25937c;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Item2 = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Item3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Container3 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 80px;
  /* align-items: center; */
`;

export const Container4 = styled.div`
  display: flex;
  margin-top: 60px;
  width: 80%;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
  margin-left: 20px;
`;

export const ColumnHeading = styled.h2`
  font-size: 36px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const ColumnParagraph = styled.p`
  color: #939393;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnRight = styled.div``;

export const ColumnImage = styled.img`
  height: 320px;
  width: 420px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Container5 = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 50px;
  width: 80%;
  margin-bottom: 60px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
