import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
  Highlighted,
} from "./BlogComponent5Elements";

const BlogComponentFiveBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading> How Do Solar Panels Produce Electricity?</MainHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog6.png"}
            alt=""
          ></Image>

          <SubHeading>Basic Solar Components</SubHeading>
          <Paragraph>
            For this you need to understand how they are made. Many solar panels
            use silicon, one of the planet’s most common elements. But since
            creating silicon crystals of suitable quality is difficult and
            expensive, home solar systems are usually built from similar, but
            less expensive materials, such as copper, indium, gallium and
            selenide (CIGS). These aren’t as efficient as high-quality silicon,
            but still provide adequate power at a reasonable cost.
          </Paragraph>
          <Paragraph>
            During manufacturing, small amounts of other elements are introduced
            to alter the electrical properties of the silicon atoms. Strips of
            negative (n-type) silicon, which has an extra electron and positive
            (p-type) silicon that is missing one electron, are sandwiched
            together. The combination forms a photovoltaic cell. And when
            multiple photovoltaic cells are placed side by side under glass,
            they give us common solar panels.
          </Paragraph>
          <Paragraph>
            Inside each solar panel is a conductive metal plate connected to
            wires that lead to a fused array combiner. Energy from the array is
            sent through an inverter, which transforms the initial direct
            electrical current into the alternating electrical current required
            to power human-made structures.
          </Paragraph>

          <SubHeading>
            4 Factors That Impact Solar Electricity Production
          </SubHeading>
          <Paragraph>
            <Highlighted>1. Shade.</Highlighted> Shaded solar panels will not
            produce the same amount of energy as those in direct sunlight. If
            your roof is sun-deprived by un-trimmable trees or building, solar
            may not be your best choice.
          </Paragraph>
          <Paragraph>
            <Highlighted>2. Seasonality.</Highlighted> Like the weather, solar
            energy production varies day-by-day and month-to-month. A cloudy,
            winter day won’t be as productive as a sunny, summer one. But it is
            important to focus on the year-round picture. For example, snow can
            sometimes reflect light and improve PV performance.
          </Paragraph>
          <Paragraph>
            <Highlighted>3. Tilt.</Highlighted> Solar panels can benefit from a
            good tilting. The direction your home is facing, its location, and
            even your roof’s pitch, have a significant effect on how well a
            residential solar system works. Ideally, solar panels should be at
            the same angle as the latitude where they are mounted. Roof pitches
            between 20 degrees to 30 degrees usually work well in most scenarios
            in Australia.
          </Paragraph>
          <Paragraph>
            <Highlighted>4. Azimuth.</Highlighted> The solar azimuth angle is
            the compass direction from where the sunlight is coming. At noon,
            the sun’s light comes from the south in the Northern Hemisphere and
            from the north in the Southern Hemisphere. The wrong azimuth angle
            could reduce the energy output of a solar home panel by up to 35%.
            An azimuth of zero (facing the equator) is usually the best choice.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentFiveBody;
