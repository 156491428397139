import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
} from "./BlogComponent4Elements";

const BlogComponentFourBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading>
            {" "}
            What Is the Life Expectancy of a Solar Battery?
          </MainHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog4.jpg"}
            alt=""
          ></Image>
          <Paragraph>
            According to recent data, 7 out of 10 solar buyers express interest
            in adding a battery to their solar systems. Home energy storage lets
            you keep the excess electricity your solar panels produce during the
            day and use it when you need it most, such as back-up power during a
            power outage, at night, or when utility rates are the highest.
          </Paragraph>
          <SubHeading>How Long Does a Solar Battery Last?</SubHeading>
          <Paragraph>
            Home solar battery units last anywhere between 5 and 15 years. If
            you decide to install a solar battery today, it’s almost certain
            you’ll need a replacement in the future to match the 20- to 30-year
            lifespan of your solar power system.
          </Paragraph>
          <Paragraph>
            Certain factors may prolong your solar battery’s life. Here is a
            simple list:
          </Paragraph>
          <SubHeading>5 Factors That Affect Home Solar Battery Life</SubHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog5.jpg"}
            alt=""
          ></Image>
          <SubHeading>1. Solar Battery Type</SubHeading>
          <Paragraph>
            If you are asking, “What are the best batteries for solar power?”
            then you need to know that solar power batteries are made with one
            of three different chemical compositions: lead acid, lithium ion, or
            saltwater. Of the three types, lithium-ion batteries (also known as
            li-ion batteries) are the most common sources of energy storage for
            a home solar system.
          </Paragraph>
          <SubHeading>
            Lithium-Ion Batteries:
          </SubHeading>
          <Paragraph>
            When it comes to home energy storage systems, lithium-ion batteries
            are the gold standard because they're lighter, more compact, and
            have a longer lifespan and higher DoD than lead-acid batteries. Not
            only that, but higher demand has decreased the cost of lithium-ion
            batteries by 85%, making solar power backed by solar storage that
            much more attainable.
          </Paragraph>
          <SubHeading>
            Lead-Acid Batteries:
          </SubHeading>
          <Paragraph>
            Lead-acid batteries have been used in off-grid energy systems for
            decades, and while they’re one of the least expensive options on the
            market, lead-acid batteries have a shorter lifespan, and lower depth
            of discharge (DoD) compared to lithium-ion batteries. The opposite
            of charging, a battery’s DoD reflects the amount of power the
            battery can discharge relative to its full kilowatt-hours (kWh)
            capacity.
          </Paragraph>
          <SubHeading>2. Solar Battery Usage:</SubHeading>
          <Paragraph>
            The life expectancy of a solar battery is mostly determined by its
            usage cycles. Luckily, most solar batteries are generally deep-cycle
            batteries, which allows them to discharge up to 80% of their stored
            energy before recharging.
          </Paragraph>
          <Paragraph>
            Some battery banks need to be manually discharged before recharging.
            With the advancement in technologies, new solar batteries charges
            automatically to avoid overcharging and to ensure your battery stays
            healthy and efficient for as long as possible.
          </Paragraph>
          <SubHeading>3. Solar Battery Temperature</SubHeading>
          <Paragraph>
            <Paragraph>
              Some battery banks need to be manually discharged before
              recharging. With the advancement in technologies, new solar
              batteries charges automatically to avoid overcharging and to
              ensure your battery stays healthy and efficient for as long as
              possible.
            </Paragraph>
          </Paragraph>{" "}
          <Paragraph>
            For example, if you live in areas with temperate/mild temperatures,
            your battery could be installed outdoors. Whereas, in regions with
            harsh temperatures, your battery will perform optimally only if it’s
            installed in your garage or basement.
          </Paragraph>
          <SubHeading>4. Solar Battery Warranty</SubHeading>
          <Paragraph>
            Solar battery manufacturers offer a wide range of warranties for
            their battery products. Typically, lead-acid batteries are found on
            the low-end of the warranty spectrum, and lithium-ion batteries are
            covered for 10 years or more.
          </Paragraph>
          <SubHeading>5. Solar Battery Maintenance</SubHeading>
          <Paragraph>
            Proper maintenance will play a key role in your solar battery’s
            longevity. Maintaining your solar system costs AU$150-$300 annually,
            while repairs could cost anywhere between AU$100-$3,500.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentFourBody;
