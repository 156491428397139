import React from "react";
import {
  HeroContainer,
  Herobg,
  Imagebg,
  Text,
  Container,
  ColumnLeft,
  ColumnImage,
  ColumnParagraph,
  ColumnPoints,
  ColumnPointsText,
  Highlighted,
  ColumnRight,
} from "./ServicespageTwoHeroElements";

const ServicesPageTwoHero = () => {
  return (
    <>
      <HeroContainer>
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/ServicesPageTwoHero.jpg"}
            alt=""
          />
          <Text>
            Home Energy <br></br>Management.
          </Text>
        </Herobg>
      </HeroContainer>

      <Container>
        <ColumnLeft>
          <ColumnImage
            src={process.env.PUBLIC_URL + "./assests/ServicesPageTwoBody.png"}
            alt=""
          ></ColumnImage>
        </ColumnLeft>
        <ColumnRight>
          <ColumnParagraph>
            Our eq energy management system brings a wide range of Energy Management tools for your
            home. Our specialists in software and advance internet applications ensures the EQ EMS works
            for you. This system helps to monitor energy consumption closely through your mobile device
            with greater precision. The energy monitoring will help you keep track of your home appliances
            which are consuming a larger amount of electricity enabling you to upgrade and significantly
            reduce your carbon footprint.
          </ColumnParagraph>
          <ColumnPoints>
            <ColumnPointsText>
              <Highlighted> Level 1 </Highlighted>
              Real time and historical energy data
            </ColumnPointsText>
            <ColumnPointsText>
              <Highlighted> Level 2 </Highlighted>
              Combined analysis of electricity solar and other utilities
            </ColumnPointsText>
            <ColumnPointsText>
              <Highlighted> Level 3 </Highlighted>
              Automate energy flows
            </ColumnPointsText>
          </ColumnPoints>
        </ColumnRight>
      </Container>
    </>
  );
};

export default ServicesPageTwoHero;
