import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h4 {
    color: #25937c;
  }
  h1 {
    margin-top: -10px;
    color: #25937c;
    font-size: 34px;

    @media screen and (max-width: 768px) {
      font-size: 25px;
    }
  }
  p {
    display: flex;
    width: 734px;
    height: 100px;
    margin-top: -10px;
    text-align: center;
    color: #939393;
    font-size: 14px;
    line-height: 26px;

    @media screen and (max-width: 768px) {
      width: 90vw;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
export const X = styled.text`
  color: #1d252b;
`;

export const Container1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 650px;
  background: #fff;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  /* margin-left: 50px; */
  margin-top: 10px;
  flex-direction: column;
  /* margin-left: 350px; */
  align-items: center;


  @media screen and (max-width: 768px) {
    margin-left: 50px;
  }

  img {
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    width: 300px;
    height: 300px;
  }
  h3 {
    justify-content: flex-end;
    align-items: center;

    display: flex;
    width: 129px;
    height: 24px;
    font-size: 18px;
    margin-top: 310px;
  }
  p {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: 298px;
    height: 204px;
    font-size: 14px;
    line-height: 26px;
    color: #939393;
    text-align: center;
  }
`;

export const ColumnMiddle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  align-items: center;


  img {
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    width: 300px;
    height: 300px;
  }
  h3 {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    width: 231px;
    height: 24px;
    font-size: 18px;
    margin-top: 310px;
  }
  p {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    width: 298px;
    height: 204px;
    font-size: 14px;
    line-height: 26px;
    color: #939393;
    text-align: center;
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  /* margin-right: 300px; */
  margin-top: 10px;
  flex-direction: column;
  align-items: center;

  img {
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    width: 300px;
    height: 300px;
  }
  h3 {
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    margin-top: 310px;
    width: 80%;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 80vw;
    }
  }
  p {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    width: 298px;
    height: 204px;
    font-size: 14px;
    line-height: 26px;
    color: #939393;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    margin-left: 50px;
    margin-right: 0px;
  }
`;
