import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 550px;
  position: relative;
  z-index: 1;
  /* width: 100%; */

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
`;
export const Text = styled.div`
  position: absolute;
  left: 45px;
  top: 50px;
  font-size: 44px;
  color: #25937c;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    left: 20px;
    font-size: 26px;
    line-height: 37px;
  }
`;

export const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ColumnLeft = styled.div`
  margin-left: 100px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
`;

export const ColumnHeading = styled.h2`
  font-size: 32px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;
export const ColumnSubHeading = styled.h3`
  font-size: 22px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Highlited = styled.span`
  color: #25937c;
`;

export const ColumnParagraph = styled.p`
  color: #939393;
  font-size: 14px;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnRight = styled.div``;

export const ColumnImage = styled.img`
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Container5 = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 50px;
  width: 70%;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
