import React from "react";
import BlogComponentSixBody from "../components/BlogComponent6";
import Footer  from "../components/Footer";
import Navbar from "../components/Navbar";

const BlogcomponentSix = () => {
  return <>
      <Navbar/>
      <BlogComponentSixBody/>
      <Footer/>
  </>;
};

export default BlogcomponentSix;
