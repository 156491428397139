import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 500px;
    margin-bottom: 160px;
  }
`;

export const Container = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
`;

export const Imagebg = styled.img`
  padding-top: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container1 = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 800px;
  flex-direction: column;
  height: 200px;
  z-index: 2;
  top: 2200px;
  background: #fff;
  /* margin-left: 142px; */
  box-shadow: 6px 9px 23px #00000017;

  @media screen and (max-width: 768px) {
    z-index: 4;
    width: 90vw;
    top: 3700px;
    margin-left: 0px;
  }

  h5 {
    color: #25937c;
    align-items: center;
  }

  h1 {
    align-items: center;
    align-items: center;
    margin-top: -20px;
    color: #25937c;
    font-size: 34px;
  }
  p {
    justify-content: center;
    display: flex;
    width: 554px;
    height: 48px;
    margin: -25px;
    color: #939393;
    font-size: 14px;
    line-height: 26px;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 80vw;
      height: 15px;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      margin: 0px;
    }
  }
`;

export const X = styled.text`
  color: #1d252b;
`;

export const Container2 = styled.div`
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 800px;
  flex-direction: column;
  height: 250px;
  z-index: 2;
  /* top: 3000px; */
  background: #fff;
  /* margin-left: 142px; */
  box-shadow: 6px 9px 23px #00000017;
  position: relative;
  top: -117px;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 90vw;
    top: 4100px;
    margin-left: 0px;
    height: 154px;
  }

  h1 {
    /* align-items: center; */
    align-items: center;
    color: #25937c;
    font-size: 34px;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }
  h2 {
    font-size: 110px;
    margin-top: -30px;
    color: #25937c;

    @media screen and (max-width: 768px) {
      font-size: 33px;
      margin-top: -6px;
    }
  }

  div {
    display: flex;
    flex-direction: row !important;
  }
`;

export const Head1 = styled.h3`
  margin-top: -110px;
  position: relative;
  left: -210px;

  @media screen and (max-width: 768px) {
    left: -35px;
    margin-top: -25px;
    font-size: 12px;
  }
`;
export const Head2 = styled.h3`
  margin-top: -110px;
  position: relative;
  left: -18px;

  @media screen and (max-width: 768px) {
    left: -10px;
    margin-top: -25px;
    font-size: 12px;
  }
`;
export const Head3 = styled.h3`
  margin-top: -110px;
  position: relative;
  left: 200px;

  @media screen and (max-width: 768px) {
    left: 35px;
    margin-top: -25px;
    font-size: 12px;
  }
`;
