import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
} from "./BlogComponent2Elements";

const BlogComponentTwoBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading> Do Solar Panels Increase Home Value?</MainHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog2.jpg"}
            alt=""
          ></Image>

          <SubHeading>
            Solar Panels and Battery Storage Can Help You Build Long-Term
            Financial Gains
          </SubHeading>
          <Paragraph>
            Homeowners, potential buyers and appraisers know that solar panels
            and battery storage can increase your home’s value on the real
            estate market.
          </Paragraph>
          <Paragraph>
            Specific numbers vary between each installation and property. Yet
            recent studies show an average increase in resale value.
          </Paragraph>
          <Paragraph>
            The price of an average rooftop solar installation and battery is
            generally recouped in the home’s sale price. Plus, households
            purchasing solar are rewarded with lower energy bills and healthy
            tax incentives that can cover the system’s costs multiple times over
            the life of its warranty.
          </Paragraph>
          <SubHeading>
            Australian Buyers Appreciate Solar’s Added Value
          </SubHeading>
          <Paragraph>
            Buyers consider monthly electric bills when purchasing a home. So,
            when assessing the long-term financial value, they are increasingly
            open to the premium of a solar-ready home.
          </Paragraph>
          <Paragraph>
            It is being recognised that installing solar panels is viewed as an
            upgrade, just like a renovated kitchen, and homebuyers across the
            country are willing to pay a premium for the economic and
            environmental benefits of a solar home.
          </Paragraph>
          <Paragraph>
            With lower monthly electricity bills and government incentives for
            new installations, Australian homeowners can realistically
            anticipate not only recovering their initial investment but also
            gaining a handsome return on their investment. If you purchase a
            solar energy system, you can expect to add a sizeable percentage to
            your property value. It is the smart, sustainable energy solution
            and is encouraging more Australians to go solar every day.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentTwoBody;
