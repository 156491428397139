import React from "react";
import Navbar from "../components/Navbar";
import AboutDescription from "../components/AboutDescription";
import Footer from "../components/Footer";
import AboutValue from "../components/AboutValue";
import AboutProcess from "../components/AboutProcess";

const Aboutuspage = () => {
  return (
    <>
      <Navbar />
      <AboutDescription />
      <AboutValue />
      <AboutProcess />
      <Footer />
    </>
  );
};

export default Aboutuspage;
