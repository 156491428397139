import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import NewsPageBody from '../components/NewsPageBody'

const Newspage = () => {
    return (
       <>
           <Navbar/>
           <NewsPageBody/>
           <Footer/>
       </>
    )
}

export default Newspage;
