import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Highlighted = styled.span`
  color: #25937c;
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Text = styled.div`
  position: absolute;
  left: 45px;
  top: 30px;
  font-size: 44px;
  color: #25937c;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    left: 20px;
    font-size: 26px;
    line-height: 37px;
  }
`;

export const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container4 = styled.div`
  display: flex;
  margin-top: 60px;
  width: 65%;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
  margin-left: 20px;
`;

export const ColumnHeading = styled.h2`
  font-size: 34px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const ColumnParagraph = styled.p`
  color: #939393;
  font-size: 16px;
  line-height: 25px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnRight = styled.div``;

export const ColumnImage = styled.img`
  width: 420px;
  height: 300px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Container5 = styled.div`
  display: flex;
  margin-top: 50px;
  width: 65%;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
