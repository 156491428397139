import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Text = styled.div`
  position: absolute;
  left: 45px;
  top: 400px;
  font-size: 44px;
  color: #fff;
  line-height: 60px;
  color: white;

  @media screen and (max-width: 768px) {
    left: 20px;
    font-size: 26px;
    line-height: 37px;
  }
`;

export const Container = styled.div`
  display: flex;
  margin-top: 60px;
  width: 60%;
  margin-left: 20%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnLeft = styled.div`
  /* margin-left: 20px; */
`;

export const ColumnParagraph = styled.p`
  color: #1d252b;
  font-size: 14px;
  line-height: 25px;
  color: #939393;
  /* width: 50%; */

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnImage = styled.img`
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnRight = styled.div`
  margin-left: 30px;
`;

export const ColumnPoints = styled.div``;
export const ColumnPointsText = styled.p`
  color: #1d252b;
  font-size: 14px;
  color: #939393;
`;
export const Highlighted = styled.span`
  color: #000;
`;
