import React from "react";
import {
  Section,
  Container,
  ColumnLeft,
  ColumnRight,
  X,
} from "./TestimonialElements";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Testimonials = () => {
  return (
    <Section>
      <Carousel autoPlay={true} infiniteLoop={true} showArrows={true}>
        <Container>
          <ColumnLeft>
            <img src={process.env.PUBLIC_URL + "./assests/Sam.png"} alt="" />
            <div id="sq1"></div>
          </ColumnLeft>
          <ColumnRight>
            <h5>TESTIMONIALS</h5>
            <h1>
              .<X>People&nbsp;</X>Says
            </h1>
            <p>
              Slickhead grunion lake trout. Canthigaster rostrate spikefish
              brown trout loach summer flounder European minnow black dragonfish
              orbicular batfish stingray tenpounder! Flying characin herring,
              Moses sole sea snail grouper discuss. European eel slender snipe
              eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black
              triplefin Atlantic saury swordfish"
            </p>
            <h2>---&emsp;Sam Peters, CEO Solar Systems LLC</h2>
          </ColumnRight>
        </Container>
        <Container>
          <ColumnLeft>
            <img src={process.env.PUBLIC_URL + "./assests/Sam.png"} alt="" />
            <div id="sq1"></div>
          </ColumnLeft>
          <ColumnRight>
            <h5>TESTIMONIALS</h5>
            <h1>
              .<X>People&nbsp;</X>Says
            </h1>
            <p>
              Slickhead grunion lake trout. Canthigaster rostrate spikefish
              brown trout loach summer flounder European minnow black dragonfish
              orbicular batfish stingray tenpounder! Flying characin herring,
              Moses sole sea snail grouper discuss. European eel slender snipe
              eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black
              triplefin Atlantic saury swordfish"
            </p>
            <h2>---&emsp;Sam Peters, CEO Solar Systems LLC</h2>
          </ColumnRight>
        </Container>
        <Container>
          <ColumnLeft>
            <img src={process.env.PUBLIC_URL + "./assests/Sam.png"} alt="" />
            <div id="sq1"></div>
          </ColumnLeft>
          <ColumnRight>
            <h5>TESTIMONIALS</h5>
            <h1>
              .<X>People&nbsp;</X>Says
            </h1>
            <p>
              Slickhead grunion lake trout. Canthigaster rostrate spikefish
              brown trout loach summer flounder European minnow black dragonfish
              orbicular batfish stingray tenpounder! Flying characin herring,
              Moses sole sea snail grouper discuss. European eel slender snipe
              eel. Gulper eel dealfish ocean sunfish; rohu yellow-and-black
              triplefin Atlantic saury swordfish"
            </p>
            <h2>---&emsp;Sam Peters, CEO Solar Systems LLC</h2>
          </ColumnRight>
        </Container>
      </Carousel>
    </Section>
  );
};

export default Testimonials;
