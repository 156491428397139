import styled from "styled-components";

export const Section = styled.section`
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
`;

export const MainHeading = styled.h1`
    font-size: 52px;
    margin-bottom: 30px;

`;

export const Image = styled.img``;

export const Paragraph = styled.p`
    font-size: 18px;
    color: #939393;
`;

export const SubHeading = styled.h2`
    font-size: 30px;
`