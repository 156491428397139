import React from "react";
import {
  Section,
  Container,
  X,
  Container1,
  ColumnMiddle,
  ColumnRight,
  ColumnLeft,
} from "./ServiceElements";

class Services extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <h4>SERVICES</h4>
          <h1>
            .<X>We Deliver&nbsp;</X>Awesome Things
          </h1>
          <p>
            Our team is working on new ideas to make our project deliveries more
            streamlined enabling our customers to have an awesome working
            experience with us. Be it using the latest tracking technologies or
            enhanced product efficiencies, we are exploring and delivering
            innovative solutions every single day.
          </p>
        </Container>
        <Container1>
          <ColumnLeft>
            <img src={process.env.PUBLIC_URL + "./assests/Solar1.jpg"} alt="" />
            <h3>Solar & battery</h3>
            <p>
              We can help you with generating and storing your electricity.
              Powering your home with solar and battery storage delivers you
              benefits for years to come. You’ll be making the most of your
              solar panels, maximising your energy independence, and lowering
              your ongoing energy bills.
            </p>
          </ColumnLeft>
          <ColumnMiddle>
            <img
              src={process.env.PUBLIC_URL + "./assests/monitor.jpg"}
              alt=""
            />
            <h3>Monitoring & management</h3>
            <p>
              We have enabled our customers to track their sites with smart
              solar monitoring solutions which allows them to control and
              monitor electricity usage via their smart phone and monitor remote
              system performance on the go direct from the mobile device, for
              maximum solar energy harvesting.
            </p>
          </ColumnMiddle>
          <ColumnRight>
            <img
              src={process.env.PUBLIC_URL + "./assests/building.jpg"}
              alt=""
            />
            <h3>Building feasibility</h3>
            <p>
              We utilise a collaborative approach with the project team and the
              owner to provide a successful design solution that is practical in
              terms of installation and also certifies that the roof is
              structurally sound to support the solar load.
            </p>
          </ColumnRight>
        </Container1>
      </Section>
    );
  }
}
export default Services;
