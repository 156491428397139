import { Switch, Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import configurestore from "./Store/configurestore";
import history from "./history";
import "./App.css";

import Homepage from "./container/homepage";
import Aboutuspage from "./container/aboutuspage";
import Servicespage from "./container/servicespage";
import ServicespageTwo from './container/servicespageTwo'
import ServicesPageThree from "./container/servicesPageThree";
import Sustainabilitypage from "./container/sustainabilitypage";
import SustainabilityTwo from "./container/sustainabilityTwo";
import Contactpage from "./container/contactpage";
import Communitypage from "./container/communitypage";
import Newspage from "./container/newspage";
import BlogcomponentOne from "./container/blogcomponentOne";
import BlogcomponentTwo from "./container/blogcomponentTwo";
import BlogcomponentThree from "./container/blogcomponentThree";
import BlogcomponentFour from "./container/blogcomponentFour";
import BlogcomponentFive from "./container/blogcomponentFive";
import BlogcomponentSix from "./container/blogcomponentSix";
import BlogcomponentSeven from "./container/blogcomponentSeven";
import ScrollToTop from "./container/ScrollToTop";
import signIn from "./container/signIn";

const Store = configurestore({});

function App() {
  return (
    <>
      <Provider store={Store}>
        <div>
          <Router history={history}>
            <div className="app">
              <ScrollToTop />
              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/home" component={Homepage} />

                <Route exact path="/about" component={Aboutuspage} />
                <Route exact path="/community" component={Communitypage} />


                <Route exact path="/solarbatterydesign" component={Servicespage} />
                <Route exact path="/homeenergy" component={ServicespageTwo} />
                <Route exact path="/structuralassesment" component={ServicesPageThree} />


                <Route exact path="/newsandinsights" component={Newspage} />
                <Route exact path="/sustainabletargets" component={Sustainabilitypage} />
                <Route exact path="/healthsafety" component={SustainabilityTwo} />

                <Route exact path="/offices" component={Contactpage} />

                <Route exact path="/blogOne" component={BlogcomponentOne} />
                <Route exact path="/blogTwo" component={BlogcomponentTwo} />
                <Route exact path="/blogThree" component={BlogcomponentThree} />
                <Route exact path="/blogFour" component={BlogcomponentFour} />
                <Route exact path="/blogFive" component={BlogcomponentFive} />
                <Route exact path="/blogSix" component={BlogcomponentSix} />
                <Route exact path="/blogSeven" component={BlogcomponentSeven} />
                <Route exact path="/signin" component={signIn} />
              </Switch>
            </div>
          </Router>
        </div>
      </Provider>
    </>
  );
}

export default App;
