import React, { useState } from "react";
import Axios from "axios";
import PopUp from "../PopUp";
import PopUp2 from "../PopUp2";

import {
  HeroContainer,
  Herobg,
  Imagebg,
  Text,
  Highlited2,
  ParentContainer,
  ColumnLeft,
  Form,
  Input,
  TextArea,
  Button,
  ColumnHeading,
  Highlited,
  ColumnRight,
  Box,
  Row1,
  Row2,
  BoxText1,
  BoxText2,
  Container5,
} from "./ContactPageBodyElements";

const SustainibilityPageOne = () => {
  const url = "https://www.eqgauge.com/api/eqrenewable/office/";

  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [errorbuttonPopUp, setErrorButtonPopUp] = useState(false);

  const [data, setData] = useState({
    name: "",
    subject: "",
    phone_no: Number,
    email: "",
    comment: "",
  });

  function submit(e) {
    e.preventDefault();
    // setButtonPopUp(true);

    Axios.post(
      url,
      {
        name: data.name,
        subject: data.subject,
        phone_no: data.phone_no,
        email: data.email,
        comment: data.comment,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(res.data);
        setButtonPopUp(true);
      })
      .catch((err) => setErrorButtonPopUp(true));
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    console.log(newdata);
  }

  const clickHandler = () => {
    alert("Form Submitted Successfully!");
  };

  return (
    <>
      <HeroContainer>
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/contactPage.png"}
            alt=""
          />
          <Text>
            <Highlited2>eqrenewable </Highlited2>
            <br></br>Our Office
          </Text>
        </Herobg>
      </HeroContainer>

      <ParentContainer>
        <Container5>
          <ColumnLeft>
            <ColumnHeading>
              <Highlited>. </Highlited>We are here
              <Highlited> For You</Highlited>
            </ColumnHeading>

            <Form onSubmit={(e) => submit(e)}>
              <Row1>
                <Input
                  onChange={(e) => handle(e)}
                  id="name"
                  value={data.name}
                  type="text"
                  // name="fullname"
                  placeholder="Name"
                />
                <Input
                  onChange={(e) => handle(e)}
                  id="subject"
                  value={data.subject}
                  type="text"
                  // name="subject"
                  placeholder="Subject"
                />
              </Row1>
              <Row2>
                <Input
                  onChange={(e) => handle(e)}
                  id="phone_no"
                  value={data.phone_no}
                  type="number"
                  // name="phone"
                  placeholder="Phone"
                />
                <Input
                  onChange={(e) => handle(e)}
                  id="email"
                  value={data.email}
                  type="email"
                  name="email"
                  placeholder="Email"
                />
              </Row2>
              <TextArea
                onChange={(e) => handle(e)}
                id="comment"
                value={data.comment}
                // name="comment"
                placeholder="Comment"
                rows="4"
              />
              <Button type="submit"> Send </Button>
              <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
                <h1>Form Submitted Successfully!!</h1>
              </PopUp>
              <PopUp2
                trigger={errorbuttonPopUp}
                setTrigger={setErrorButtonPopUp}
              >
                <h1>The form in not filled properly!</h1>
              </PopUp2>
            </Form>
          </ColumnLeft>
          <ColumnRight>
            <Box>
              <BoxText1>
                We provide half an hour obligation free consultation through one
                of our experienced solar consultants to assess the feasibility
                of your future renewable investment. If solar or battery is
                feasible investment for your site, a complimentary proposal will
                be shared with you enabling you to make a sound decision.
              </BoxText1>
              <BoxText2>Thank you!</BoxText2>
            </Box>
          </ColumnRight>
        </Container5>
      </ParentContainer>
    </>
  );
};

export default SustainibilityPageOne;
