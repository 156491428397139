import React from "react";
import {
  Nav,
  NavMenu,
  NavLink,
  Content,
  ContentLink,
  Navbtn,
  NavbtnLink,
  Background,
  NavContainer,
  NavItem,
  Highlighted,
  NavB,
  NavHamburger,
  NavbtnResponsive,
  // LogoLink,
} from "./navbarelements";

class Navbar extends React.Component {
  state = { click: false };

  handleClick = () => {
    this.setState({ click: !this.state.click });
  };

  render() {
    return (
      <Nav>
        <NavContainer>
          <a href="/home">
            <Background
              src={process.env.PUBLIC_URL + "./assests/logo.svg"}
              alt=""
            />
          </a>

          <NavHamburger className="nav__hamburger" onClick={this.handleClick}>
            <i className={this.state.click ? "fas fa-times" : "fas fa-bars"} />
          </NavHamburger>
          <NavMenu
            className={this.state.click ? "nav-links active" : "nav-links"}
          >
            <NavItem>
              <NavLink>
                <NavB>
                  eq <Highlighted> approach </Highlighted>
                </NavB>
                <Content className="toggle">
                  <ContentLink to="/about">About Us</ContentLink>
                  <ContentLink to="/community">Community Program</ContentLink>
                </Content>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/services">
                <NavB>
                  eq <Highlighted> services </Highlighted>
                </NavB>
                <Content className="toggle">
                  <ContentLink to="/solarbatterydesign">
                    Solar & Battery Design
                  </ContentLink>
                  <ContentLink to="/homeenergy">
                    Home energy management{" "}
                  </ContentLink>
                  <ContentLink to="/structuralassesment">
                    Building structural assessment{" "}
                  </ContentLink>
                </Content>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/sustainability">
                <NavB>
                  eq <Highlighted> sustainability </Highlighted>
                </NavB>
                <Content className="toggle">
                  <ContentLink to="/newsandinsights">News and insights</ContentLink>
                  <ContentLink to="/sustainabletargets">
                    Sustainable Targets{" "}
                  </ContentLink>
                  <ContentLink to="/healthsafety">
                    Health and Safety
                  </ContentLink>
                </Content>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink to="/contact" activeStyle>
                <NavB>
                  contact <Highlighted> eq </Highlighted>
                </NavB>
                <Content className="toggle">
                  <ContentLink to="/offices">Offices</ContentLink>
                  {/* <ContentLink>Contact Us</ContentLink> */}
                </Content>
              </NavLink>
            </NavItem>

            <NavbtnResponsive>
              <NavbtnLink to="/signin">Get In Touch</NavbtnLink>
            </NavbtnResponsive>
          </NavMenu>
        </NavContainer>
        <Navbtn>
          <NavbtnLink to="/signin">Get In Touch</NavbtnLink>
        </Navbtn>
      </Nav>
    );
  }
}
export default Navbar;
