import React from "react";

import {
  Footer,
  FooterContainer,
  ColumnLeft,
  ColumnLeftImage,
  ColumnLeftIcons,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  ColumnLeftText,
  ColumnMiddle,
  ColumnMiddleHeading,
  ColumnMiddleLinks,
  ColumnMiddleLinksItem,
  FooterLinks,
  FooterLinksText,
  ColumnRight,
  ColumnRightHeading,
  ColumnRightInput,
  ColumnRightButton,
} from "./FooterElements";

const FooterElements = () => {
  return (
    <>
      <Footer>
        <FooterContainer>
          <ColumnLeft>
            <ColumnLeftImage
              src={process.env.PUBLIC_URL + "./assests/logo8.png"}
              alt="logo"
            ></ColumnLeftImage>
            <ColumnLeftIcons>
              <FacebookIcon>
                <i class="fab fa-facebook-square"></i>
              </FacebookIcon>
              <InstagramIcon>
                <i className="fab fa-instagram"></i>
              </InstagramIcon>
              <TwitterIcon>
                <i className="fab fa-twitter"></i>
              </TwitterIcon>
            </ColumnLeftIcons>
            <ColumnLeftText>
              eqrenewable <br></br>
              Level 10, 1 Queens Road, Melbourne VIC 3004 <br></br>
              +61 03 8806 1259 <br></br>
              info@eq-renewable.com.au <br></br>
            </ColumnLeftText>
          </ColumnLeft>
          <ColumnMiddle>
            <ColumnMiddleHeading>Company and Links</ColumnMiddleHeading>
            <ColumnMiddleLinks>
              <ColumnMiddleLinksItem>
                <FooterLinks to="/about">
                  <FooterLinksText>eq approach</FooterLinksText>
                </FooterLinks>
              </ColumnMiddleLinksItem>
              <ColumnMiddleLinksItem>
                <FooterLinks to="/solarbatterydesign">
                  <FooterLinksText>eq services</FooterLinksText>
                </FooterLinks>
              </ColumnMiddleLinksItem>
              <ColumnMiddleLinksItem>
                <FooterLinks to="/newsandinsights">
                  <FooterLinksText>eq sustainability</FooterLinksText>
                </FooterLinks>
              </ColumnMiddleLinksItem>
              <ColumnMiddleLinksItem>
                <FooterLinks to="/offices">
                  <FooterLinksText>contact eq</FooterLinksText>
                </FooterLinks>
              </ColumnMiddleLinksItem>
            </ColumnMiddleLinks>
          </ColumnMiddle>
          <ColumnRight>
            <ColumnRightHeading>Subscribe</ColumnRightHeading>
            <ColumnRightInput
              type="email"
              placeholder="Email"
            ></ColumnRightInput>
            <ColumnRightButton>Submit</ColumnRightButton>
          </ColumnRight>
        </FooterContainer>
      </Footer>
    </>
  );
};

export default FooterElements;
