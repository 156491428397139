import React from "react";
import {
  Section,
  Container,
  ColumnLeft,
  ColumnRight,
  Para,
  Container1,
  Highlighted
} from "./PurposeElements";

class Purpose extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <ColumnLeft>
            <div class="square">
              <p>
                Our<br></br>Purpose
              </p>
              <div class="square1">
                <p className="eq">eq</p>
              </div>
            </div>
          </ColumnLeft>
          <ColumnRight>
            <h1>Energising <Highlighted>sustainable Community</Highlighted> </h1>
            <Para>
              EQ-Renewable has been established aiming to be provide the most
              feasible solution to their end customer and be recognised as the
              most efficient organisations in the renewable industry. The
              organisation has invested on onboarding extremely talented and
              experienced personnel to have an efficient customer services and
              support experience.
            </Para>
          </ColumnRight>
        </Container>
        <Container1>
          <img src={process.env.PUBLIC_URL + "./assests/logo1.png"} alt="" />
          <img src={process.env.PUBLIC_URL + "./assests/logo2.png"} alt="" />
          <img src={process.env.PUBLIC_URL + "./assests/logo3.png"} alt="" />
          <img src={process.env.PUBLIC_URL + "./assests/logo4.png"} alt="" />
          <img className="imageChange" src={process.env.PUBLIC_URL + "./assests/logo5.png"} alt="" />
        </Container1>
      </Section>
    );
  }
}
export default Purpose;
