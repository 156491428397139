import styled from "styled-components";
import { Link } from "react-router-dom";

export const BlogLink = styled(Link)`
  text-decoration: none;
`;

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Text = styled.div`
  position: absolute;
  bottom: 35px;
  left: 70px;
  font-size: 44px;
  color: #fff;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    left: 20px;
    font-size: 26px;
    line-height: 37px;
  }
`;

export const Section = styled.section`
  margin-top: 100px;
`;


export const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Container = styled.div`
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: flex-start; */

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 300px;
  height: 431px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 20px;
`;
export const Card2 = styled.div`
  width: 300px;
  height: 246px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 20px;
`;
export const CardImage = styled.img`
  width: 300px;
  height: 192px;
`;

export const CardBody = styled.div`
  margin: 12px;
  /* margin-top: 20px; */
`;

export const CardSubtitle = styled.p`
  font: normal normal bold 8px/1px Manrope;
  letter-spacing: 0px;
  color: #939393;
  position: relative;
  top: 12px;
`;
export const CardTitle = styled.h3`
  text-align: left;
  font: normal normal bold 21px/24px Manrope;
  letter-spacing: 0px;
  color: #1d252b;
`;
export const CardDate = styled.p`
  font: normal normal bold 8px/1px Manrope;
  letter-spacing: 0px;
  color: #25937c;
`;
export const CardParagraph = styled.p`
  text-align: left;
  font: normal normal normal 11px/24px Manrope;
  letter-spacing: 0px;
  color: #939393;
`;
