import React from "react";

import {
  Section,
  Container1,
  Container1Heading,
  Paragraph,
  Container2,
  Container2Heading,
  ImageContainer,
  Item1,
  Image,
  ImageText,
  Item2,
  Item3,
  Container3,
  Container4,
  ColumnLeft,
  ColumnHeading,
  ColumnParagraph,
  ColumnRight,
  ColumnImage,
  Container5,
  Highlighted,
} from "./ServicesBodyElements";

const ServicesBody = () => {
  return (
    <>
      <Section>
        <Container1>
          <Container1Heading>
            Solar & Battery <Highlighted>Design</Highlighted>{" "}
          </Container1Heading>
          <Paragraph>
            We aim to create sustainable living spaces which are embedded in our solar design and
            installation services. Each building has a unique architecture hence, our solutions are aligned to
            cater to the specific required aesthetics needs.
          </Paragraph>
        </Container1>
        <Container2>
          <Container2Heading>
            Residentials <Highlighted>
            Stand Alone.</Highlighted>{" "}
          </Container2Heading>
          <ImageContainer>
            <Item1>
              <Image
                src={process.env.PUBLIC_URL + "./assests/servicesImage1.png"}
                alt=""
              ></Image>
              <ImageText>Traditional</ImageText>
            </Item1>
            <Item2>
              <Image
                src={process.env.PUBLIC_URL + "./assests/servicesImage2.png"}
                alt=""
              ></Image>
              <ImageText>Contemporary</ImageText>
            </Item2>
            <Item3>
              <Image
                src={process.env.PUBLIC_URL + "./assests/servicesImage3.png"}
                alt=""
              ></Image>
              <ImageText>Unique Architecture</ImageText>
            </Item3>
          </ImageContainer>
        </Container2>
        <Container3>
          <Container2Heading>
            Mixed-use <Highlighted>Building.</Highlighted>{" "}
          </Container2Heading>
          <ImageContainer>
            <Item1>
              <Image
                src={process.env.PUBLIC_URL + "./assests/servicesImage4.png"}
                alt=""
              ></Image>
              <ImageText>Apartments</ImageText>
            </Item1>
            <Item2>
              <Image
                src={process.env.PUBLIC_URL + "./assests/servicesImage5.png"}
                alt=""
              ></Image>
              <ImageText>High Rise</ImageText>
            </Item2>
            <Item3>
              <Image
                src={process.env.PUBLIC_URL + "./assests/servicesImage6.png"}
                alt=""
              ></Image>
              <ImageText>Community Spaces</ImageText>
            </Item3>
          </ImageContainer>
        </Container3>

        <Container4>
          <ColumnLeft>
            <ColumnHeading>
              Battery <Highlighted>Design</Highlighted>{" "}
            </ColumnHeading>
            <ColumnParagraph>
              Our inhouse engineers are members of various technological groups and are up to date on the
              current advancements on home energy storage systems. The team is closely working towards
              both hybrid and off-grid solutions to achieve the most feasible and economical designs. We have
              aligned ourselves with local Australian battery manufacturers such as Redback enabling us to
              provide piece of mind to our customers in terms of local support, high quality, and warranty
              claims experience.
            </ColumnParagraph>
          </ColumnLeft>
          <ColumnRight>
            <ColumnImage
              src={process.env.PUBLIC_URL + "./assests/servicesImage7.jpg"}
              alt=""
            ></ColumnImage>
          </ColumnRight>
        </Container4>

        <Container5>
          <ColumnLeft>
            <ColumnHeading>
              Lighting <Highlighted>Upgrade</Highlighted>{" "}
            </ColumnHeading>
            <ColumnParagraph>
              We have inhouse designers that can support our customer with their
              lighting requirements in the residential and commercial space. To
              strengthen this support, we have aligned our procurement team with
              an efficient network of local energy efficient lighting providers.
              Services include site assessment, Dialux report, glare and
              compliance assessment in accordance to Australian standards.
            </ColumnParagraph>
          </ColumnLeft>
          <ColumnRight>
            <ColumnImage
              src={process.env.PUBLIC_URL + "./assests/servicesImage8.png"}
              alt=""
            ></ColumnImage>
          </ColumnRight>
        </Container5>
      </Section>
    </>
  );
};

export default ServicesBody;
