import React from "react";
import { HeroContainer, Herobg, Imagebg, Text } from "./HeroElements";

class HeroSection extends React.Component {
  render() {
    return (
      <HeroContainer id="home">
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/houses.jpg"}
            alt=""
          />
          <Text>
            Energising&nbsp;sustainable<br></br>Community
          </Text>
        </Herobg>
      </HeroContainer>
    );
  }
}
export default HeroSection;
