import React from "react";
import {
  HeroContainer,
  Herobg,
  Imagebg,
  Text,
  ParentContainer,
  Container4,
  ColumnLeft,
  ColumnHeading,
  ColumnParagraph,
  ColumnRight,
  ColumnImage,
  Container5,
  Highlighted,
} from "./ServicesPageThreeElements";

const ServicesPageThree = () => {
  return (
    <>
      <HeroContainer>
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/structuralImage.png"}
            alt=""
          />
          <Text>
            Building<br></br>Structural<br></br>assessment.
          </Text>
        </Herobg>
      </HeroContainer>

      <ParentContainer>
        <Container4>
          <ColumnLeft>
            <ColumnHeading>
              Solar <Highlighted> Roof Assessment</Highlighted>{" "}
            </ColumnHeading>
            <ColumnParagraph>
              Our inhouse engineers review the capacity of your existing roof structures to
              determine if they will support the solar panels weight. After verifying the structure, we
              provide technical recommendations to our installation team, which in turn will be
              used to install your new system safely.
            </ColumnParagraph>
          </ColumnLeft>
          <ColumnRight>
            <ColumnImage
              src={
                process.env.PUBLIC_URL + "./assests/ServicesPageThreeImage2.jpg"
              }
              alt=""
            ></ColumnImage>
          </ColumnRight>
        </Container4>

        <Container5>
          <ColumnLeft>
            <ColumnHeading>
              Building Structural{" "}
              <Highlighted>Stability Assessment</Highlighted>
            </ColumnHeading>
            <ColumnParagraph>
              Apart from solar installations on your roof, our structural engineers can also assess
              your homes structural suitability enabling you to utilise their recommendations for
              any upgrade or cladding works required on your house to make it structurally safe
              and sound.
            </ColumnParagraph>
          </ColumnLeft>
          <ColumnRight>
            <ColumnImage
              src={
                process.env.PUBLIC_URL + "./assests/ServicesPageThreeImage3.jpg"
              }
              alt=""
            ></ColumnImage>
          </ColumnRight>
        </Container5>
      </ParentContainer>
    </>
  );
};

export default ServicesPageThree;
