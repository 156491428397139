import React, { useState } from "react";
import Axios from "axios";
import PopUp from "../PopUp";
import PopUp2 from "../PopUp2";

import {
  HeroContainer,
  Herobg,
  Imagebg,
  Section,
  Container1,
  Container1Heading,
  Highlighted,
  Container1Paragraph,
  Container2,
  Container2ColumnLeft,
  ColumnLeftImage,
  Container2ColumnRight,
  ColumnRightParagraph,
  Container2ColumnRightHighlighted,
  Container3,
  Container3ColumnLeft,
  Container3ColumnLeftHeading,
  Container3ColumnLeftParagraph,
  Container3ColumnRight,
  Highlighted3,
  Form,
  Row,
  Row2,
  Input,
  Button,
  FormHeading,
  Label,
  Input2,
} from "./CommunityPageBodyElements";

const CommunityPageBody = () => {
  const url = "https://www.eqgauge.com/api/eqrenewable/community/";

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_no: Number,
    ref_first_name: "",
    ref_last_name: "",
    ref_email: "",
    ref_phone_no: Number,
  });

  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [errorbuttonPopUp, setErrorButtonPopUp] = useState(false);

  function submit(e) {
    e.preventDefault();
    // setButtonPopUp(true);

    Axios.post(
      url,
      {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_no: data.phone_no,
        ref_first_name: data.ref_first_name,
        ref_last_name: data.ref_last_name,
        ref_email: data.ref_email,
        ref_phone_no: data.ref_phone_no,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(res.data);
        setButtonPopUp(true);
      })
      .catch((err) => setErrorButtonPopUp(true));
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    console.log(newdata);
  }

  const clickHandler = () => {
    alert("Form Submitted Successfully!");
  };

  return (
    <>
      <HeroContainer id="home">
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/communityHeroImage.png"}
            alt=""
          />
        </Herobg>
      </HeroContainer>

      <Section>
        <Container1>
          <Container1Heading>
            <Highlighted> . </Highlighted> Community enlightment
            <Highlighted> Key Criteria</Highlighted>
          </Container1Heading>
          <Container1Paragraph>
            Community enlightenment is a key criterion which drives our project
            delivery strategies. We are proud to be heavily engaged with local
            councils and institutions delivering knowledge sharing sessions
            enabling rapid promotion of renewable technologies and their impact
            on climate change.
          </Container1Paragraph>
        </Container1>

        <Container2>
          <Container2ColumnLeft>
            <ColumnLeftImage
              src={process.env.PUBLIC_URL + "./assests/CommunityImage2.jpg"}
              alt=""
            />
          </Container2ColumnLeft>
          <Container2ColumnRight>
            <ColumnRightParagraph>
              <Container2ColumnRightHighlighted>
                EQ w/Local
              </Container2ColumnRightHighlighted>
              - we assess and try to engage local skill set to carryout
              associated tasks during the project execution.
            </ColumnRightParagraph>
            <ColumnRightParagraph>
              <Container2ColumnRightHighlighted>
                EQ w/Busines
              </Container2ColumnRightHighlighted>
              - we run educational programs with support from local
              business/institutions to enlighten a larger community with the
              help of an extensive learning program delivered either through
              personalised small group session or online webinars.
            </ColumnRightParagraph>
          </Container2ColumnRight>
        </Container2>

        <Container3>
          <Container3ColumnLeft>
            <Container3ColumnLeftHeading>
              Referral <Highlighted>Program</Highlighted>
            </Container3ColumnLeftHeading>
            <Container3ColumnLeftParagraph>
              We congratulate you for being part of the he clean energy
              initiatives. If any of your friends, relatives, colleagues, or the
              business you work with is interested in contributing towards clean
              energy, you may be able to pass on the benefits of your awareness
              to them.
            </Container3ColumnLeftParagraph>
            <Container3ColumnLeftParagraph>
              With every referral towards a positive change and carbon
              reduction, we will be happy to serve as below:
            </Container3ColumnLeftParagraph>

            <Container3ColumnLeftParagraph>
              <Highlighted>•</Highlighted>A contribution towards a charity
              organisation of your choice
            </Container3ColumnLeftParagraph>
            <Container3ColumnLeftParagraph>
              <Highlighted>•</Highlighted>An instant cash back in the
              proposition provided to your referral.
            </Container3ColumnLeftParagraph>
            <Container3ColumnLeftParagraph>
              <Highlighted>•</Highlighted>A $650 discount voucher to utilise our
              consultancy services
            </Container3ColumnLeftParagraph>
          </Container3ColumnLeft>
          <Container3ColumnRight>
            <Form onSubmit={(e) => submit(e)}>
              <FormHeading>
                <Highlighted3>.</Highlighted3> Your Details{" "}
              </FormHeading>
              <Row>
                <Input
                  onChange={(e) => handle(e)}
                  id="first_name"
                  value={data.first_name}
                  type="text"
                  // name="firstname"
                  placeholder="*First Name"
                />
                <Input
                  onChange={(e) => handle(e)}
                  id="last_name"
                  value={data.last_name}
                  type="text"
                  // name="lastname"
                  placeholder="*Last Name"
                />
              </Row>
              <Row>
                <Input
                  onChange={(e) => handle(e)}
                  id="email"
                  value={data.email}
                  type="email"
                  // name="email"
                  placeholder="*Email"
                />
                <Input
                  onChange={(e) => handle(e)}
                  id="phone_no"
                  value={data.phone_no}
                  type="number"
                  // name="phone"
                  placeholder="*Phone Number"
                />
              </Row>
              <Row2>
                {/* <Input2
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  placeholder="*Phone Number"
                /> */}
                {/* <Label for="checkbox"> I forgot the referral code</Label> */}
              </Row2>
              <FormHeading>
                <Highlighted3>.</Highlighted3> Referee Details{" "}
              </FormHeading>

              <Row>
                <Input
                  onChange={(e) => handle(e)}
                  id="ref_first_name"
                  value={data.ref_first_name}
                  type="text"
                  // name="refereefirstname"
                  placeholder="*Referee First Name"
                />
                <Input
                  onChange={(e) => handle(e)}
                  id="ref_last_name"
                  value={data.ref_last_name}
                  type="text"
                  // name="refereelastname"
                  placeholder="*Referee Last Name"
                />
              </Row>
              <Row>
                <Input
                  onChange={(e) => handle(e)}
                  id="ref_email"
                  value={data.ref_email}
                  type="email"
                  // name="refereeemail"
                  placeholder="*Referee Email"
                />
                <Input
                  onChange={(e) => handle(e)}
                  id="ref_phone_no"
                  value={data.ref_phone_no}
                  type="number"
                  // name="refereephone"
                  placeholder="*Referee Phone Number"
                />
              </Row>

              <Button type="submit"> Submit </Button>

              <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
                <h1>Form Submitted Successfully!!</h1>
              </PopUp>
              <PopUp2
                trigger={errorbuttonPopUp}
                setTrigger={setErrorButtonPopUp}
              >
                <h1>The form in not filled properly!</h1>
              </PopUp2>
            </Form>
          </Container3ColumnRight>
        </Container3>
      </Section>
    </>
  );
};

export default CommunityPageBody;
