import React from "react";
import CountUp from "react-countup";

import {
  Section,
  Container,
  ColumnLeft,
  Paragraph,
  Heading,
  ColumnRight,
  Image,
  ValueText,
  Text,
  Container2,
  X,
  Head1,
  Head2,
  Head3,
} from "./AboutValueElements";

const AboutValue = () => {
  return (
    <>
      <Section>
        <Container>
          <ColumnLeft>
            <Paragraph>
              We focus on designing sustainable spaces that connect the
              <br></br>
              environment, people, emotion, and technology.
            </Paragraph>
            <Heading>Commitment</Heading>
            <Paragraph>
              Making sure we deliver what we have committed to our clients
              <br></br>
              as we value the importance of their investments and to enhance
              <br></br>
              agility to deliver quality to our network of customers repeatedly.
            </Paragraph>
            <Heading>Community</Heading>
            <Paragraph>
              We seek to create and contribute to social value in the <br></br>
              communities in which operate through the positive social and
              <br></br>
              economic benefits generated by our core business.
            </Paragraph>
            <Heading>Safety and health</Heading>
            <Paragraph>
              As a profession, we are proud of a long track record of safe
              <br></br>
              operations. We adhere to stringent WHS management systems{" "}
              <br></br>and have achieved our safety record in the most hazardous
              of <br></br>
              environments.
            </Paragraph>
            <Heading>Quality</Heading>
            <Paragraph>
              Our core principle is to explore the finest possibilities to
              <br></br>
              deliver highly efficient solutions to our customers.
            </Paragraph>
          </ColumnLeft>
          <ColumnRight>
            <Image
              src={process.env.PUBLIC_URL + "./assests/AboutValue.png"}
              alt=""
            ></Image>
            <ValueText>
              <Text>Our Value</Text>
            </ValueText>
          </ColumnRight>
        </Container>

        <Container2>
          <h1>
            .<X>Targeted numbers&nbsp;</X>and Only
          </h1>
          <h2>
            <CountUp end={19} duration={15} />
            &emsp;
            <CountUp end={620} duration={15} />
            &emsp;
            <CountUp end={3} prefix={0} duration={15} />
          </h2>
          <div>
            <Head1>Councils</Head1>
            <Head2>Projects</Head2>
            <Head3>States</Head3>
          </div>
        </Container2>
      </Section>
    </>
  );
};

export default AboutValue;
