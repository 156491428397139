import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
} from "./BlogComponent7Elements";

const BlogComponentSevenBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading>Can Solar Panels Break?</MainHeading>

          <SubHeading>How are Solar Panels Protected?</SubHeading>
          <Paragraph>
            Solar panels are made of solar cells and encased in durable glass
            for protection. Beneath the glass there is a layer of insulation and
            a protective back sheet. This technology protects against heat and
            humidity inside the panels to keep your home solar system running
            smoothly. Your solar panels also have an anti-reflective coating
            that allows them to absorb maximum power from the sun.
          </Paragraph>
          <SubHeading>
            Guarding Against Physical Damage to Solar Panels
          </SubHeading>

          <Paragraph>
            Your solar array is built to last. Solar codes and standards are
            rigorous, and the solar industry is committed to developing
            cutting-edge technology to make our planet a cleaner place to live.
          </Paragraph>
          <Paragraph>
            A typical solar array lasts 25-30 years, but this does not mean they
            stop producing electricity. Solar experts report that panels can
            continue to work for many decades, provided you take care of them
            and they are not physically damaged.
          </Paragraph>

          <Paragraph>
            There are very few moving parts in a solar system, which means there
            are very few mechanical parts that could break. But there are some
            conditions that could cause physical damage.
          </Paragraph>

          <SubHeading>3 Common Ways Solar Panels Can Be Damaged:</SubHeading>
          <SubHeading>
          Twigs, Leaves and Dirt:
          </SubHeading>
          <Paragraph>
            Debris can scratch your solar panels and lower the amount of energy
            produced. Maintain the trees around your home so that branches and
            dirt do not fall on your roof. Clean your solar panels with a garden
            hose and a soft cloth once a year for maximum efficiency.
          </Paragraph>
          <SubHeading>
          Hailstorms:
          </SubHeading>
          <Paragraph>
            Bad weather is damaging to all roofs, and hailstorms are no
            exception. Our solar technicians will guide you toward the right
            solar set up if there are any extreme conditions in your area.
          </Paragraph>

          <SubHeading>
          Water Damage:
          </SubHeading>
          <Paragraph>
            Your solar panels are sealed just like your windows. Check the
            sealing just as you would your windows to ensure a water-tight fit.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentSevenBody;
