import styled from "styled-components";

export const Section = styled.section`
  display: none;
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  top: -80px;
  /* margin-left: 20px; */

  @media screen and (max-width: 768px) {
    margin-bottom: -120px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-template-rows: 520px;
  background: #fff;

  @media screen and (max-width: 768px) {
    /* grid-template-columns:1fr;
    align-items:center; */

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: right;
  img {
    position: relative;
    width: 60% !important;
    /* height: 447px; */
    padding-top: 60px;
    z-index: 1;

    @media screen and (max-width: 768px) {
      width: 100% !important;
      margin-bottom: -70px;
    }
  }
  div.sq1 {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 66px;
    height: 106px;
    background: #25937c;
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin-top: 100px;

  h5 {
    color: #25937c;
    align-items: center;
    width: 30%;
    text-align: left;

    @media screen and (max-width: 768px) {
      align-items: center;
      margin-left: 32px;
    }
  }

  h1 {
    margin-top: -20px;
    align-items: center;
    color: #25937c;
    font-size: 36px;
    width: 30%;
    text-align: left;

    @media screen and (max-width: 768px) {
      margin-left: 32px;
      margin-bottom: -40px;
    }
  }
  p {
    display: flex;
    width: 750px;
    height: 100px;
    color: #939393;
    font-size: 14px;
    line-height: 26px;
    text-align: left;
    /* align-items: center; */
    width: 90%;

    @media screen and (max-width: 768px) {
      width: 90vw;
      margin: 32px;
      margin-top: 71px;
      width: 80vw;
    }
  }

  h2 {
    color: #25937c;
    font-size: 18px;
    line-height: 26px;
    width: 46%;
    text-align: left;

    @media screen and (max-width: 768px) {
      margin-left: 32px;
      margin-top: 180px;
      width: 80vw;
    }
  }
`;
export const X = styled.text`
  color: #1d252b;
  text-align: left;
`;
