import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const ColumnLeft = styled.div`
  padding: 10px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Paragraph = styled.p`
  color: #939393;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    width: 90%
  }
`;

export const Heading = styled.h3`
  color: black;
`;

export const ColumnRight = styled.div`
@media screen and (max-width: 768px) {
    width: 90%
  }

`;

export const Image = styled.img`
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 10px;
  }
`;

export const ValueText = styled.div`
  position: relative;
  top: -450px;
  left: 25px;
`;

export const Text = styled.text`
  color: #fff;
  font-size: 37px;
`;

export const X = styled.text`
  color: #1d252b;
`;

export const Container2 = styled.div`
  display: flex;
  align-items: center;
  width: 800px;
  flex-direction: column;
  height: 250px;
  z-index: 2;
  background: #fff;
  /* margin-left: 142px; */
  box-shadow: 6px 9px 23px #00000017;
  /* position: relative; */

  @media screen and (max-width: 768px) {
    /* position: absolute; */
    width: 90vw;
    top: 3719px;
    margin-left: 0px;
    height: 154px;
  }

  h1 {
    align-items: center;
    align-items: center;
    color: #25937c;
    font-size: 34px;

    @media screen and (max-width: 768px) {
      font-size: 22px;
    }
  }
  h2 {
    font-size: 110px;
    margin-top: -30px;
    color: #25937c;
    position: relative;
    left: 14px;

    @media screen and (max-width: 768px) {
      font-size: 33px;
      margin-top: -6px;
    }
  }

  div {
    display: flex;
    flex-direction: row !important;
  }
`;

export const Head1 = styled.h3`
  margin-top: -110px;
  position: relative;
  left: -198px;

  @media screen and (max-width: 768px) {
    left: -35px;
    margin-top: -25px;
    font-size: 12px;
  }
`;
export const Head2 = styled.h3`
  margin-top: -110px;
  position: relative;
  left: -8px;

  @media screen and (max-width: 768px) {
    left: -10px;
    margin-top: -25px;
    font-size: 12px;
  }
`;
export const Head3 = styled.h3`
  margin-top: -110px;
  position: relative;
  left: 210px;

  @media screen and (max-width: 768px) {
    left: 35px;
    margin-top: -25px;
    font-size: 12px;
  }
`;
