import React from "react";

import {
  ParentContainer,
  ColumnLeft,
  ColumnHeading,
  Highlited,
  ColumnParagraph,
  ColumnRight,
  ColumnSubHeading,
  ColumnImage,
  Container5,
  Space,
} from "./SustainabilityPageTwoElements";

const SustainabilityPageTwo = () => {
  return (
    <>
      <ParentContainer>
        <Container5>
          <ColumnLeft>
            <ColumnSubHeading>Health & Safety</ColumnSubHeading>
            <ColumnHeading>
              <Highlited>. </Highlited>Safety first in<br></br>{" "}
              <Highlited>Design and Operations</Highlited>
            </ColumnHeading>
            <ColumnParagraph>
              We are proud of our long track record of safe operations. We adhere to stringent WHS
              management systems and have achieved our safety record in the most hazardous of
              environments.
            </ColumnParagraph>
            <ul>
              <li>
                Encouraging consultation and participation in all matters
                relating to health and safety in our design and the workplace.
              </li>
              <li>
                Encouraging active and timely reporting and recording of all
                incidents and injuries and investigating these incidents in a
                timely manner to ensure contributing factors are identified and
                corrective action is taken.
              </li>
              <li>
                Meeting all our obligations under relevant WHS laws and
                regulations, standards, and guidelines.
              </li>
              <li>
                Identifying hazards in the workplace and taking practical steps
                to eliminate, isolate or minimise potential exposure to these
                hazards.
              </li>
            </ul>
          </ColumnLeft>
          <ColumnRight>
            <ColumnImage
              src={
                process.env.PUBLIC_URL +
                "./assests/SustainabilityPageTwoImage1.png"
              }
              alt=""
            ></ColumnImage>
          </ColumnRight>
        </Container5>
      </ParentContainer>
    </>
  );
};

export default SustainabilityPageTwo;
