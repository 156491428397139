import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  background: #fff;
  height: 89px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: sticky;
  top: 0;
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 89px;
  z-index: 1;
  width: 100%;
  /* padding: 0 24px; */
  /* max-width: 1100px; */
  font-size: 18px;

  @media screen and (max-width: 768px) {
    flex-direction: row-reverse;
    padding: 10px;
  }
`;

export const Background = styled.img`
  /* width: 228.63px; */
  height: 36px;
  /* padding-left: 10px; */
  padding-top: 26px;
  /* width: 100%; */
  position: relative;
  left: 50px;

  @media screen and (max-width: 768px) {
    width: 70vw;
    margin-top: 28px;
    padding: 0px;
    position: relative;
    left: -38px;
  }
`;

// export const LogoLink = styled.link`
//   cursor: pointer;
// `;

export const NavHamburger = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 26px;
  }

  & .fa-bars:before {
    font-size: 39px;
    color: #25937c;
  }

  & .fa-times:before {
    font-size: 39px;
    color: #25937c;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  text-align: center;
  /* margin-right: -40px; */
  list-style-type: none;
  /* margin-left: -80px; */

  @media screen and (max-width: 768px) {
    &.nav-links {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 0px;
      margin-right: 0px;
      width: 100vw;
      position: absolute;
      top: 68px;
      left: -150%;
      padding: 0px;
      height: 542px;
      background-color: white;
    }

    &.nav-links.active {
      background: white;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      width: 100vw;
    }
  }
`;

export const NavItem = styled.li`
  height: 89px;
  position: relative;
  left: 85px;

  @media screen and (max-width: 768px) {
    left: 0px;
  }
`;

export const NavLink = styled.div`
  color: #25937c;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  width: 150px;
  padding: 0 5px;
  text-decoration: none;
  flex-direction: column;

  &:hover .toggle {
    display: block;
  }
`;

export const Highlighted = styled.span`
  color: #25937c;
`;
export const NavB = styled.text`
  color: #1d252b;
  margin-top: 30px;
  font-size: 17px;
  font-weight: normal;
`;

export const Content = styled.div`
  background-color: #fff;
  width: 170px;
  margin-top: 88px;
  display: none;
  position: absolute;
  z-index: 2;
`;

export const ContentLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  color: #25937c;
  font-size: 14px;
  padding: 10px 20px;
`;

export const Navbtn = styled.nav`
  display: flex;
  align-items: center;
  /* margin-right: 70px; */
  margin-left: 108px;
  width: 20%;

  

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavbtnResponsive = styled.nav`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 0px;
    margin-top: 20px;
  }
`;

export const NavbtnLink = styled(Link)`
  background: #fff;
  padding-top: 5px;
  padding-bottom: 6px;
  padding-left: 38px;
  padding-right: 38px;
  color: #25937c;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #25937c;

  :hover{
    color: #fff;
    background: #25937c;
  }
`;
