import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SustainabilityPageTwo from "../components/SustainabilityPageTwo";

const SustainabilityTwo = () => {
  return (
    <>
      <Navbar />
      <SustainabilityPageTwo/>
      <Footer />
    </>
  );
};

export default SustainabilityTwo;
