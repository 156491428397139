import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
`;
export const Text = styled.div`
  position: absolute;
  left: 55px;
  top: 180px;
  font-size: 44px;
  color: #fff;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    left: 20px;
    font-size: 26px;
    line-height: 37px;
  }
`;

export const Highlited2 = styled.div`
  font-size: 18px;
  margin-bottom: -80px;
  font-weight: 100;

  @media screen and (max-width: 768px) {
    margin-bottom: -40px;
  }
`;

export const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ColumnLeft = styled.div`
  margin-left: 20px;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const ColumnHeading = styled.h2`
  font-size: 32px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const Highlited = styled.span`
  color: #25937c;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Row1 = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Row2 = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Input = styled.input`
  margin-bottom: 30px;
  padding: 10px;
  padding-left: 0px;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #707070;
  font-size: 12px;
  margin: 10px;
  width: 50%;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
  }
`;

export const TextArea = styled.textarea`
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #707070;
  margin: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
    margin-bottom: 40px;
  }
`;
export const Button = styled.button`
  margin: 10px;
  margin-top: 30px;
  width: 130px;
  /* background: #25937c; */
  background: none;
  border: 1px solid #25937c;
  padding: 10px;
  outline: none;
  cursor: pointer;
  color: #25937c;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
  }
`;

export const ColumnRight = styled.div``;

export const Box = styled.div`
  width: 280px;
  height: 380px;
  background: #25937c;
  color: #fff;
`;
export const BoxText1 = styled.p`
  padding: 15px;
  font-size: 14px;
  line-height: 28px;
  padding-top: 20px;
`;
export const BoxText2 = styled.p`
  padding-left: 15px;
  padding-top: 20px;
  font-size: 16px;
`;

export const Container5 = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 50px;
  width: 90%;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
