import styled from "styled-components";

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #25937c;
  }
`;

export const PopupInner = styled.div`
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: #fff;
`;

export const Button = styled.button`
  position: absolute;
  /* top: 16px; */
  right: 16px;
  bottom: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 2px;

 
`;
