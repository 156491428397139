import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
} from "./BlogComponent3Elements";

const BlogComponentThreeBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading>
            {" "}
            Will Solar Panels Work During a Power Outage?
          </MainHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog3.jpg"}
            alt=""
          ></Image>
          <Paragraph>
            Solar battery storage keeps your solar systems working during an
            outage with reliable, renewable solar power, and why we need clean
            energy now more than ever.
          </Paragraph>

          <SubHeading>
            Why Do I Need a Solar Battery During a Power Outage?
          </SubHeading>
          <Paragraph>
            The electric grid shuts down during extreme weather conditions or if
            consumer demand overloads the system. This power outage would
            include your solar panel system. Utilities can also shut down if
            they think the grid will become overloaded.
          </Paragraph>
          <Paragraph>
            One of the reasons for a shutdown is to protect utility technicians
            who are sent to fix damaged power lines. The other reason is that
            your solar panel system is connected to the grid via a solar
            inverter (or “grid-connected solar”). The inverter runs to a smart
            meter that records the amount of energy you use, and the excess
            solar energy that is sent back to the utility. So, if the grid shuts
            down, so will your solar panels.
          </Paragraph>
          <Paragraph>
            (As a reminder, do not forget about net metering (Feed In Tariff) .
            If you have net metering plans in your state, you can save even more
            by selling your excess energy back to your local utility provider.
          </Paragraph>
          <Paragraph>
            The only way for your solar panels to continue generating power
            during a blackout is solar battery storage. Today, more and more
            utility companies are seeing the importance of solar storage and are
            finding ways to incorporate more renewable energy into their
            infrastructure.
          </Paragraph>

          <SubHeading>Power Through Blackouts with a Solar Battery </SubHeading>
          <Paragraph>
            Power through blackouts with a clean, reliable solar battery and
            take control of your energy storage. By diverting the excess solar
            energy your panels create to your battery and backup panel, you can
            power essential appliances and the most important rooms in your
            home.
          </Paragraph>
          <Paragraph>
            While a generator sometimes offers a temporary solution in the face
            of a blackout, it runs on dirty fossil fuels, can be disruptive and
            at worst dangerous. Batteries makes energy independence safe, simple
            and hassle-free. You also have the freedom to choose what and how
            much you want to back up in case of an outage.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentThreeBody;
