import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background: #fff;
`;
export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 350px;
  background: #fff;

  @media screen and (max-width: 768px) {
    /* grid-template-columns:1fr;
     align-items:center; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const ColumnLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  div.square {
    position: relative;
    height: 314px;
    width: 307px;
    background-color: #25937c;
    /* margin-left: 200px; */

    @media screen and (max-width: 768px) {
      height: 257px;
      width: 262px;
      margin-left: 20px;
    }
  }
  p {
    position: absolute;
    font-size: 46px;
    color: white;
    padding-left: 45px;
    padding-top: 74px;
  }

  .eq{
    position: absolute;
    font-size: 46px;
    color: white;
    padding-left: 30px;
    /* padding-top: px; */
    top: -95px;
  }

  div.square1 {
    position: absolute;
    height: 114px;
    width: 109px;
    background-color: #333333;
    margin-left: 230px;
    margin-top: 230px;

    @media screen and (max-width: 768px) {
      height: 92px;
      width: 90px;
      margin-left: 216px;
      margin-top: 191px;
    }
  }
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  h1 {
    font-size: 30px;
    line-height: 44px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`;

export const Para = styled.div`
  display: flex;
  width: 587px;
  height: 126px;
  letter-spacing: 0px;
  color: #939393;
  opacity: 1;
  font-size: 14px;
  line-height: 26px;

  @media screen and (max-width: 768px) {
    width: 90vw;
    font-size: 15px;
    margin-left: 20px;
  }
`;

export const Container1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  /* width: 90%; */
  img {
    top: 50px;
    width: 125px;
    height: 56px;
    margin: 0 20px;
  }
  & .imageChange{
      height: 100px;
    }
  @media screen and (max-width: 768px) {
    /* width: 100vw; */
    /* height: 103px; */
    flex-direction: column;
    margin-top: 100px;
  }
`;
