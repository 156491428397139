import React from "react";
import {
  HeroContainer,
  Herobg,
  Imagebg,
  Text,
  Section,
  Container,
  Card,
  Card2,
  CardImage,
  CardSubtitle,
  CardTitle,
  CardDate,
  CardParagraph,
  CardBody,
  BlogLink,
  ParentContainer,
} from "./NewsPageBodyElements";

const NewsPageBody = () => {
  return (
    <>
      <HeroContainer id="home">
        <Herobg>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/newsPageImage1.png"}
            alt=""
          />
          <Text>News & Insights</Text>
        </Herobg>
      </HeroContainer>

      <Section>
        <ParentContainer>
          <Container>
            <BlogLink to="/blogSix">
              <Card2>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>5 Advantages of Solar Power</CardTitle>
                  <CardDate>02/12</CardDate>
                  <CardParagraph>
                    As the cost of renewable energy continues to fall, more and
                    more households are switching to clean, renewable
                    electricity sources. Among...
                  </CardParagraph>
                </CardBody>
              </Card2>
            </BlogLink>
            <BlogLink to="/blogOne">
              <Card>
                <CardImage
                  src={process.env.PUBLIC_URL + "./assests/blog1.jpg"}
                  alt=""
                ></CardImage>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>Do Solar Panels Work in frigid Weather?</CardTitle>
                  <CardDate>12/10</CardDate>
                  <CardParagraph>
                    Even in below-freezing weather, solar panels turn sunlight
                    into electricity. That is solar panels absorb energy from
                    our sun’s abundant light...
                  </CardParagraph>
                </CardBody>
              </Card>
            </BlogLink>

            <BlogLink to="/blogTwo">
              <Card>
                <CardImage
                  src={process.env.PUBLIC_URL + "./assests/blog2.jpg"}
                  alt=""
                ></CardImage>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>Do Solar Panels Increase Home Value?</CardTitle>
                  <CardDate>17/12</CardDate>
                  <CardParagraph>
                    Homeowners, potential buyers and appraisers know that solar
                    panels and battery storage can increase your home’s value on
                    the real estate market...
                  </CardParagraph>
                </CardBody>
              </Card>
            </BlogLink>
          </Container>

          <Container>
            <BlogLink to="blogThree">
              <Card>
                <CardImage
                  src={process.env.PUBLIC_URL + "./assests/blog3.jpg"}
                  alt=""
                ></CardImage>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>Solar Panels & Power Outage?</CardTitle>
                  <CardDate>24/08</CardDate>
                  <CardParagraph>
                    Solar battery storage keeps your solar systems working
                    during an outage with reliable, renewable solar power, and
                    why we need clean energy...
                  </CardParagraph>
                </CardBody>
              </Card>
            </BlogLink>
            <BlogLink to="blogFour">
              <Card>
                <CardImage
                  src={process.env.PUBLIC_URL + "./assests/blog4.jpg"}
                  alt=""
                ></CardImage>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>Life Expectancy of a Solar Battery</CardTitle>
                  <CardDate>29/11</CardDate>
                  <CardParagraph>
                    According to recent data, 7 out of 10 solar buyers express
                    interest in adding a battery to their solar systems. Home
                    energy storage lets you keep ...
                  </CardParagraph>
                </CardBody>
              </Card>
            </BlogLink>
            {/* <Card2>
              <CardBody>
                <CardSubtitle>SOLAR</CardSubtitle>
                <CardTitle>Good Public Policy Key</CardTitle>
                <CardDate>31/12</CardDate>
                <CardParagraph>
                  Firefish snipy-back elasmobranch scorpio nfish sheepshead rock
                  beauty sand eel. S kilfish barramundi Rainbowfish lake chub
                  smelt...
                </CardParagraph>
              </CardBody>
            </Card2> */}
          </Container>

          <Container>
            <BlogLink to="/blogSeven">
              <Card2>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>Can Solar Panels break?</CardTitle>
                  <CardDate>01/11</CardDate>
                  <CardParagraph>
                    Solar panels are made of solar cells and encased in durable
                    glass for protection. Beneath the glass there is a layer of
                    insulation and a protective back sheet. This technology
                    protects...
                  </CardParagraph>
                </CardBody>
              </Card2>
            </BlogLink>

            <BlogLink to="/blogTwo">
              <Card2>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>Value of Solar Panels</CardTitle>
                  <CardDate>03/10</CardDate>
                  <CardParagraph>
                    Homeowners, potential buyers and appraisers know that solar
                    panels and battery storage can increase your home’s value on
                    the real estate market...
                  </CardParagraph>
                </CardBody>
              </Card2>
            </BlogLink>
            <BlogLink to="/blogFive">
              <Card>
                <CardImage
                  src={process.env.PUBLIC_URL + "./assests/blog6.png"}
                  alt=""
                ></CardImage>
                <CardBody>
                  <CardSubtitle>SOLAR</CardSubtitle>
                  <CardTitle>
                    How Do Solar Panels Produce Electricity?
                  </CardTitle>
                  <CardDate>31/12</CardDate>
                  <CardParagraph>
                    For this you need to understand how they are made. Many
                    solar panels use silicon, one of the planet’s most common
                    elements. But since creating...
                  </CardParagraph>
                </CardBody>
              </Card>
            </BlogLink>
          </Container>
        </ParentContainer>
      </Section>
    </>
  );
};

export default NewsPageBody;
