import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const Text = styled.div`
  position: absolute;
  bottom: 385px;
  left: 45%px;
  font-size: 44px;
  color: #fff;
  line-height: 60px;

  @media screen and (max-width: 768px) {
    left: 20px;
    font-size: 26px;
    line-height: 37px;
  }
`;
