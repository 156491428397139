import React from "react";
import BlogComponentFiveBody from "../components/BlogComponent5";
import Footer  from "../components/Footer";
import Navbar from "../components/Navbar";

const BlogcomponentFive = () => {
  return <>
      <Navbar/>
      <BlogComponentFiveBody/>
      <Footer/>
  </>;
};

export default BlogcomponentFive;
