import React from "react";

import {
  HeroContainer,
  Herobg,
  Imagebg,
  Text,
  ParentContainer,
  ColumnLeft,
  ColumnHeading,
  Highlited,
  ColumnSubHeading,
  ColumnParagraph,
  ColumnRight,
  ColumnImage,
  Container5,
} from "./SustainibilityPageOneElements";

const SustainibilityPageOne = () => {
  return (
    <>
      <HeroContainer>
        <Herobg>
          <Imagebg
            img
            src={
              process.env.PUBLIC_URL +
              "./assests/SustainibilityPageOneImage1.jpg"
            }
            alt=""
          />
          <Text>
            Sustainability<br></br>Targets
          </Text>
        </Herobg>
      </HeroContainer>

      <ParentContainer>
        <Container5>
          <ColumnLeft>
            <ColumnHeading>
              <Highlited>. </Highlited>Stronger Approach for <br></br>{" "}
              <Highlited>Enhanced Reliability</Highlited>
            </ColumnHeading>
            <ColumnParagraph>
              EQ renewables approach towards sustainability has been designed and based on our target to
              make our environment and communities healthier by utilising the latest technological
              advancements.
            </ColumnParagraph>
            <ColumnSubHeading>Commitment</ColumnSubHeading>
            <ColumnParagraph>
              EQ renewable takes great pride in repeatedly delivering to our clients what we have committed to
              as we value the importance of their investment.
            </ColumnParagraph>
            <ColumnSubHeading>Collaboration</ColumnSubHeading>
            <ColumnParagraph>
              Collaborative Research, investigation and integration allows us to create the best solutions for
              each project utilizing current market advancements to increase the products and projects
              performance and efficiency.
            </ColumnParagraph>
            <ColumnSubHeading>Community</ColumnSubHeading>
            <ColumnParagraph>
              Through positive social and economic benefits generated by our core business. We seek to
              create and contribute to social value in the communities in which operate.
            </ColumnParagraph>
          </ColumnLeft>
          <ColumnRight>
            <ColumnImage
              src={
                process.env.PUBLIC_URL +
                "./assests/SustainabilityPageOneImage2.png"
              }
              alt=""
            ></ColumnImage>
          </ColumnRight>
        </Container5>
      </ParentContainer>
    </>
  );
};

export default SustainibilityPageOne;
