import React from "react";
import {
  Section,
  Container,
  Imagebg,
  Container1,
  X,
  Container2,
  Head1,
  Head2,
  Head3,
} from "./BenefitElements";

import CountUp from "react-countup";

class Benefits extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <Imagebg
            img
            src={process.env.PUBLIC_URL + "./assests/Aus.png"}
            alt=""
          />
        </Container>
        <Container1>
          <h5>BENEFITS</h5>
          <h1>
            .<X>We in&nbsp;</X>Australia
          </h1>
          <p>
            We are a nationally recognised solution provider and planning to
            rapidly extend our services to the regional councils
          </p>
        </Container1>

        <Container2>
          <h1>
            .<X>Targeted numbers&nbsp;</X>and Only
          </h1>
          <h2>
            <CountUp end={19} duration={15} />
            &emsp;
            <CountUp end={620} duration={15} />
            &emsp;
            <CountUp end={3} duration={15} prefix={0} />
          </h2>
          

          <div>
            <Head1>Councils</Head1>
            <Head2>Projects</Head2>
            <Head3>States</Head3>
          </div>
        </Container2>
      </Section>
    );
  }
}
export default Benefits;
