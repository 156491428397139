import React from "react";
import {
  Section,
  Container,
  ColumnLeft,
  ColumnRight,
  X,
  BlogLink,
  Card,
  CardImage,
  CardBody,
  CardSubtitle,
  CardTitle,
  CardDate,
  CardParagraph,
} from "./BlogElements";

class Blog extends React.Component {
  render() {
    return (
      <Section>
        <Container>
          <ColumnLeft>
            <h5>BLOG</h5>
            <h1>
              .<X>News &&nbsp;</X>Insights
            </h1>
            <BlogLink to="blogOne">
              <div id="RenewEnergy">
                <img
                  src={process.env.PUBLIC_URL + "./assests/blog1.jpg"}
                  alt=""
                />
                <h4>DEC 21, 2020</h4>
                <h2>Solar Panels & frigid weather</h2>
                <p>
                  Even if you are living in rainy climates, solar power remains
                  a reliable...
                </p>
              </div>
            </BlogLink>
            <br></br>

            <BlogLink to="blogTwo">
              <div id="RenewEnergy">
                <img
                  src={process.env.PUBLIC_URL + "./assests/blog2.jpg"}
                  alt=""
                />
                <h4>DEC 27, 2020</h4>
                <h2>Solar Panels & Home Value</h2>
                <p>
                  Homeowners, potential buyers and appraisers know that solar
                  panels and battery...
                </p>
              </div>
            </BlogLink>

            <br></br>

            <BlogLink to="blogThree">
              <div id="RenewEnergy">
                <img
                  src={process.env.PUBLIC_URL + "./assests/blog3.jpg"}
                  alt=""
                />
                <h4>DEC 31, 2020</h4>
                <h2>Solar Panels & Power Outage </h2>
                <p>
                  Solar battery storage keeps your solar systems working during
                  an...
                </p>
              </div>
            </BlogLink>
          </ColumnLeft>

          <ColumnRight>
            <h5>BLOG</h5>
            <h1>
              .<X>News &&nbsp;</X>Insights
            </h1>
            <BlogLink to="blogSix">
              <Card>
                <CardImage
                  src={process.env.PUBLIC_URL + "./assests/blog7.jpg"}
                  alt=""
                ></CardImage>
                <CardBody>
                  <CardDate>31/12</CardDate>
                  <CardTitle>5 advantages of Solar Panel</CardTitle>
                </CardBody>
              </Card>
            </BlogLink>
          </ColumnRight>
        </Container>
      </Section>
    );
  }
}
export default Blog;
