import styled from "styled-components";
import { Link } from "react-router-dom";

export const Footer = styled.footer`
  background: #25272a;
  margin-top: 100px;
  width: 100%;
  height: 300px;
  display: flex;

  @media screen and (max-width: 768px) {
    height: 605px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 25px;
  }
`;

export const ColumnLeft = styled.div``;

export const ColumnLeftImage = styled.img`
  margin-bottom: 10px;
`;

export const ColumnLeftIcons = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const FacebookIcon = styled.div`
  margin: 5px;
  i {
    color: #939393;
  }
`;
export const InstagramIcon = styled.div`
  margin: 5px;
  i {
    color: #939393;
  }
`;
export const TwitterIcon = styled.div`
  margin: 5px;
  i {
    color: #939393;
  }
`;

export const ColumnLeftText = styled.div`
  color: #939393;
  font-size: 14px;
  line-height: 22px;
`;

export const ColumnMiddle = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const ColumnMiddleHeading = styled.h3`
  color: #fff;
`;

export const ColumnMiddleLinks = styled.ul`
  list-style: none;
  padding: 0px;
`;

export const ColumnMiddleLinksItem = styled.li`
  margin-bottom: 10px;
`;

export const FooterLinks = styled(Link)`
  text-decoration: none;
  color: #939393;
  font-size: 14px;
`;

export const FooterLinksText = styled.text``;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnRightHeading = styled.h3`
  color: #fff;
  text-align: center;
  position: relative;
  top: -10px;
`;

export const ColumnRightInput = styled.input`
  width: 97%;
  margin-bottom: 30px;
  padding: 10px;
  padding-left: 0px;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #707070;
  font-size: 14px; 
  color: white; 
`;

export const ColumnRightButton = styled.button`
  width: 100%;
  background: #25937c;
  padding: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  text-align: center;
`;
