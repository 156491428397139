import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 89px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Herobg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Imagebg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container1Heading = styled.h2`
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export const Highlighted = styled.span`
  color: #25937c;
`;

export const Container1Paragraph = styled.p`
  font-size: 14px;
  line-height: 25px;
  color: #939393;
  width: 40%;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Container2 = styled.div`
  display: flex;
  width: 70%;
  margin-top: 60px;
  /* align-items: center; */

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const Container2ColumnLeft = styled.div``;

export const ColumnLeftImage = styled.img`
  width: 531px;
  height: 367px;

  @media screen and (max-width: 768px) {
    width: 90%;
    height: 100%;
  }
`;

export const Container2ColumnRight = styled.div``;

export const ColumnRightParagraph = styled.p`
  font-size: 14px;
  line-height: 25px;
  color: #939393;
  margin: 25px;
  margin-top: 0px;
`;

export const Container2ColumnRightHighlighted = styled.span`
  color: #25937c;
  font-size: 30px;
`;

export const Container3 = styled.div`
  display: flex;
  width: 80%;
  /* align-items: center; */
  margin-top: 80px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Container3ColumnLeft = styled.div`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const Container3ColumnLeftHeading = styled.h2`
  font-size: 30px;
  margin-left: 25px;
  margin-top: 0px;
`;
export const Container3ColumnLeftParagraph = styled.p`
  font-size: 14px;
  line-height: 25px;
  color: #939393;
  margin: 25px;
  margin-top: 0px;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 5px;
  }
`;

export const Container3ColumnRight = styled.div`
  background: #25937c;
  height: 600px;
  /* width: 676px; */
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 750px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-top: 20px;
`;

export const FormHeading = styled.h2`
  color: #fff;
  margin-left: 20px;
`;

export const Row = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Row2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  margin-bottom: 30px;
  padding: 10px;
  padding-left: 0px;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  font-size: 12px;
  margin: 10px;
  color: white;
  width: 50%;
  margin-left: 20px;
  margin-right: 20px;

  ::placeholder {
    color: #fff;
    font-size: 10px;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0px;
    margin-bottom: 20px;
  }
`;

export const Input2 = styled.input`
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    margin: 0px;
  }
`;

export const Highlighted3 = styled.span`
  font-size: 40px;
`;

export const Label = styled.label`
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
`;

export const Button = styled.button`
  margin: 10px;
  margin-top: 30px;
  width: 130px;
  background: #fff;
  border: 1px solid #25937c;
  padding: 10px;
  outline: none;
  cursor: pointer;
  color: #25937c;
  text-align: center;
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
  }
`;
