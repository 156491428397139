import React from "react";
import BlogComponentOneBody from "../components/BlogComponent1";
import Footer  from "../components/Footer";
import Navbar from "../components/Navbar";

const BlogcomponentOne = () => {
  return <>
      <Navbar/>
      <BlogComponentOneBody/>
      <Footer/>
  </>;
};

export default BlogcomponentOne;
