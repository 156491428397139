import React from "react";

import {
  Section,
  Container,
  MainHeading,
  Image,
  Paragraph,
  SubHeading,
} from "./BlogComponent6Elements";

const BlogComponentSixBody = () => {
  return (
    <>
      <Section>
        <Container>
          <MainHeading> 5 Advantages of Solar Power</MainHeading>
          <Image
            src={process.env.PUBLIC_URL + "./assests/blog7.jpg"}
            alt=""
          ></Image>

          <SubHeading>
            How Residential Solar Can Bring Freedom and Choice to Households
          </SubHeading>
          <Paragraph>
            As the cost of renewable energy continues to fall, more and more
            households are switching to clean, renewable electricity sources.
            Among the most affordable and abundant of these is residential
            solar.
          </Paragraph>
          <SubHeading>Five Reasons to Install Home Solar Panels</SubHeading>

          <SubHeading>
          1. Provides Clean, Renewable Energy {" "}
          </SubHeading>
          <Paragraph>
            Solar power is a 100% clean, renewable energy source. It reduces
            reliance on oil, coal, and natural gas for electricity production.
            These fossil fuels produce harmful emissions that affect the quality
            of air, water, and soil and are responsible for global warming. It’s
            estimated that between 2000 and 2065, the Earth will lose more
            species of plants and animals to extinction than in the previous 65
            million years combined.
          </Paragraph>
          <Paragraph>
            In contrast, solar energy produces no pollution. The sun's abundant
            power offers an unlimited energy source that does not strip the
            landscape or harm the ozone layer. Residential solar energy systems
            represent an investment in the planet's future.
          </Paragraph>

          <SubHeading>
            2. Gives You Freedom and Control Over Electricity 
          </SubHeading>
          <Paragraph>
            Our grid needs to be modernized to meet the increasing demands of a
            plugged-in society. Adding a home battery unit powered by
            residential solar panels can store enough electricity to power homes
            through peak usage hours, thereby giving households the freedom to
            control their family’s electricity.
          </Paragraph>
          <Paragraph>
            Battery storage service along with solar panels, are also a viable
            buffer against the rolling blackouts introduced by electric
            companies for wildfire prevention. Today’s antiquated grid means
            that even communities not directly threatened by wildfires will
            experience power outages.
          </Paragraph>
          <Paragraph>
            With a residential solar system, your home is powered by rooftop
            solar panels during the day and by energy stored in the battery at
            night. By installing solar panels, you gain energy independence.
          </Paragraph>
          <Paragraph>
            Solar panels and home batteries are becoming more affordable,
            accessible, resilient, and efficient. They provide peace of mind and
            energy security for households when the power goes out, or the next
            storm strikes.
          </Paragraph>

          <SubHeading>
          3. You May Save on Your Current Utility Bill{" "}
          </SubHeading>
          <Paragraph>
            If you are facing rising home energy expenses, home solar panels can
            offset your costs.
          </Paragraph>
          <Paragraph>
            Savings can even accrue on cloudy days since the sun emits energy
            through clear and cloudy skies. Solar offers year-round efficiency
            and savings, even in colder, cloudy climates. Depending on their
            size, efficiency, and orientation relative to the sun, some solar
            panels generate more electricity than your home consumes. This could
            reduce your monthly electric bill to zero. You may qualify for a
            rebate in some areas if your residential solar power system produces
            excess electricity. Check with your area’s solar power guidelines.
          </Paragraph>
          <Paragraph>
            Most utility bills are rising every year. With our solar plan,
            customers pay predictable rates for the next 25 years.
          </Paragraph>

          <SubHeading>
            4. Costs Have Fallen{" "}
          </SubHeading>
          <Paragraph>
            The cost of solar has dropped dramatically in recent years, falling
            more than 70% in just the last decade.1 You will save money on
            maintenance too, since there are no moving parts to break down. In
            addition, the cost of home solar batteries has dropped substantially
            as well. The benchmark levelized cost of electricity (LCOE) for
            lithium-ion batteries has fallen by 35%.
          </Paragraph>
        </Container>
      </Section>
    </>
  );
};

export default BlogComponentSixBody;
