import React from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactPageBody from '../components/ContactPageBody'


const Contactpage = () => {
  return <>
      <Navbar/>
      <ContactPageBody/>
      <Footer/>
  </>;
};

export default Contactpage;
