import styled from "styled-components";

export const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ColumnLeft = styled.div`
  margin-left: 20px;

  li {
    color: #939393;

    font-size: 14px;
    width: 80%;
    position: relative;
    left: -25px;

    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
`;

export const Space = styled.span``;

export const ColumnHeading = styled.h2`
  font-size: 32px;
  color: #1d252b;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;
export const ColumnSubHeading = styled.h3`
  font-size: 22px;
  color: #25937c;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Highlited = styled.span`
  color: #25937c;
`;

export const ColumnParagraph = styled.p`
  color: #939393;

  font-size: 14px;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ColumnRight = styled.div``;

export const ColumnImage = styled.img`
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const Container5 = styled.div`
  display: flex;
  /* flex-direction: row-reverse; */
  margin-top: 50px;
  width: 70%;
  align-items: center;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
