import React from "react";

import {
  Section,
  Container,
  ColumnLeft,
  ColumnRight,
  Paragraph,
  Image,
} from "./AboutProcessElements";

const AboutProcess = () => {
  return (
    <>
      <Section>
        <Container>
          <ColumnLeft>
            <div class="square">
              <p>Our Process</p>
              <div class="square1"></div>
            </div>
          </ColumnLeft>
          <ColumnRight>
            <Paragraph>
              Our process has been developed and refined over many years{" "}
              <br></br>
              with guidance from highly recognised project management <br></br>{" "}
              framework. <br></br>The key elements of our process include
              detailed site analysis, <br></br>clear work implementation
              process, measurements and <br></br>verification activities
              including risk analysis.
            </Paragraph>
            <Image
              src={process.env.PUBLIC_URL + "./assests/AboutProcess.png"}
              alt=""
            ></Image>
          </ColumnRight>
        </Container>
      </Section>
    </>
  );
};

export default AboutProcess;
