import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CommunityPageBody from "../components/CommunityPageBody";
import { Highlighted } from "../components/Navbar/navbarelements";
import { ColumnLeft } from "../components/Footer/FooterElements";

const Communitypage = () => {
  return (
    <>
      <Navbar />
      <CommunityPageBody />
      <Footer />
    </>
  );
};

export default Communitypage;
