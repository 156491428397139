import React from "react";
import BlogComponentTwoBody from "../components/BlogComponent2";
import Footer  from "../components/Footer";
import Navbar from "../components/Navbar";

const BlogcomponentTwo = () => {
  return <>
      <Navbar/>
      <BlogComponentTwoBody/>
      <Footer/>
  </>;
};

export default BlogcomponentTwo;
